@import "../../config.sass";

.SendModalForm {
  position: relative; }

.SendModalForm > h4 {
  color: black;
  text-align: center;
  font-size: 1.6vw;
  margin-bottom: 2.33vw;
  padding: 3.79vw 1vw 0 1vw; }

.closeButton {
  cursor: pointer;
  position: absolute;
  width: 2.04vw;
  right: -1.02vw;
  top: -1.02vw;
  height: 2.04vw;
  border-radius: 0.72vw;
  background-color: $blue-color; }

.closeButton:hover {
  opacity: 1; }

.closeButton > div {
  position: absolute;
  width: 0.14vw;
  height: 1.31vw;
  left: 0.97vw;
  top: 0.4vw;
  margin: 0;
  background-color: white; }

.closeButton > div:first-child {
  transform: rotate(45deg); }

.closeButton > div:last-child {
  transform: rotate(-45deg); }

.customForm {
  flex-direction: column;
  padding: 0 9.92vw 3.79vw 9.92vw; }

.customForm :global(.ant-form-item-control-wrapper) {
  width: 100%; }

.customForm :global(input) {
  text-align: center; }

.customForm > * {
  width: 100% !important;
  margin-right: 0 !important; }

.customForm > div:first-child {
  margin-bottom: 1.16vw; }

.customForm > div:last-child > p {
  text-align: center;
  color: #010101;
  opacity: 0.4; }

.customForm > div:last-child > p > span {
  text-decoration: none;
  border-bottom: 1px dashed #010101;
  cursor: pointer; }

@media (max-width: $tablet-width) {
  .SendModalForm > h4 {
    font-size: 2.47vw;
    margin-bottom: 4.39vw;
    padding: 6.04vw 1vw 0 1vw; }

  .closeButton {
    width: 3.84vw;
    right: -1.92vw;
    top: -1.92vw;
    height: 3.84vw;
    border-radius: 1.37vw; }

  .closeButton > div {
    width: 0.27vw;
    height: 2.47vw;
    left: 1.7vw;
    top: 0.7vw; }

  .customForm {
    padding: 0 12vw 6.04vw 12vw;
    width: 100%; }

  .customForm > div:first-child {
    margin-bottom: 2.2vw; }

  .customForm > div:last-child > p {
    text-align: left;
    margin-top: 1.64vw;
    font-size: 1.64vw; } }

@media (max-width: $mobile-width) {
  .SendModalForm > h4 {
    font-size: 3.91vw;
    margin-bottom: 6.95vw;
    padding: 9.56vw 1vw 0 1vw; }

  .SupportBlock > article > p {
    font-size: 3.91vw;
    line-height: 4.78vw; }

  .closeButton {
    width: 6.08vw;
    height: 6.08vw;
    right: -3.04vw;
    top: -3.04vw;
    border-radius: 2.17vw; }

  .closeButton > div {
    width: 0.43vw;
    height: 3.91vw;
    left: 2.8vw;
    top: 1.1vw; }

  .customForm {
    padding: 0 16vw 9.56vw 16vw; }

  .customForm > div:first-child {
    margin-bottom: 3.47vw; }

  .customForm > div:last-child > p {
    margin-top: 2.6vw;
    font-size: 2.6vw; }

  .customForm > div:last-child > p > span {
    border-bottom: 1px dashed #010101; }


  .customForm > div:last-child > p > span:hover {
    border-bottom: 1px dashed $blue-color; } }

