@import "../../config.sass";

.Steps {
    display: flex;
    justify-content: space-between; }


.Steps > ul {
    width: 44vw; }

.Steps > ul > li {
    display: flex;
    align-items: flex-start; }


.Steps > ul > li > img {
    width: 3.94vw;
    min-width: 3.94vw;
    margin-right: 2.18vw; }


.Steps > ul > li {
    margin-bottom: 2.48vw; }


.Steps > ul > li:last-child {
    margin: 0; }


.text > header {
    display: inline-block;
    margin-bottom: 0.87vw;
    font-size: 2.04vw;
    line-height: 2.77vw;
    font-weight: bold; }

.textLink {
    cursor: pointer;
    transition: 0.1s; }

.textLink:hover {
    color: $blue-color; }

.text > p, .Steps > div > p {
    margin: 0;
    font-size: 1.31vw; }

.Steps > div > p {
    text-align: center;
    width: 100%; }

.text > p > a {
    cursor: pointer;
    color: $blue-color;
    border-bottom: 0.1vw solid $blue-color; }


.Steps > div {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30vw;
    height: 10.36vw;
    padding: 1.02vw 1.37vw 1.75vw 1.89vw;
    border-bottom: 0.24vw solid #dcdcdc;
    border-left: 0.24vw solid #dcdcdc;
    border-bottom-left-radius: 0.72vw; }


.Steps > div > img:first-child {
    position: absolute;
    width: 8.02vw;
    height: 11vw;
    left: -1vw;
    top: 13vw; }


.Steps > div > picture:nth-child(2) {
    width: 6.93vw;
    min-width: 6.93vw;
    height: 6.86vw;
    margin-right: 1.75vw; }

.Steps > div > picture:nth-child(2) > img {
    width: 100%; }

.modalMenu > div {
    width: 40.43vw !important; }

@media (max-width: $tablet-width) {
    .Steps {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse; }

    .Steps > ul {
        width: 100%; }

    .Steps > ul > li > img {
        width: 7.41vw;
        min-width: 7.41vw;
        margin-right: 4.12vw; }

    .Steps > ul > li {
        margin-bottom: 4.67vw; }

    .Steps > ul > li:last-child {
        margin: 0; }

    .text > header {
        margin-bottom: 1.64vw;
        font-size: 3.84vw;
        line-height: 4.94vw; }

    .text > p, .Steps > div > p {
        font-size: 2.47vw; }

    .text > p > a {
        border-bottom: 0.2vw solid $blue-color; }

    .Steps > div {
        width: 100%;
        margin-bottom: 6.86vw;
        justify-content: flex-start;
        height: 19.5vw;
        padding: 1.02vw 7.69vw;
        border: 0.43vw solid #dcdcdc;
        border-radius: 1.37vw; }

    .Steps > div > p {
        width: 34vw; }

    .Steps > div > img:first-child {
        display: none; }


    .Steps > div > picture:nth-child(2) {
        width: 13.04vw;
        min-width: 13.04vw;
        height: 12.91vw;
        margin-right: 4.39vw; }

    .modalMenu > div {
        width: 64vw !important; } }


@media (max-width: $mobile-width) {
    .Steps > ul > li > img {
        width: 11.73vw;
        min-width: 11.73vw;
        margin-right: 6.52vw; }

    .Steps > ul > li {
        margin-bottom: 7.39vw; }

    .Steps > ul > li:last-child {
        margin: 0; }

    .text > header {
        margin-bottom: 2.6vw;
        font-size: 6.08vw;
        line-height: 8.69vw; }

    .text > p, .Steps > div > p {
        font-size: 3.91vw; }


    .text > p > a {
        border-bottom: 0.4vw solid $blue-color; }

    .Steps > div {
        margin-bottom: 10.86vw;
        height: 31.73vw;
        padding: 1.02vw 3.95vw;
        border: 0.8vw solid #dcdcdc;
        border-radius: 2.17vw; }

    .Steps > div > p {
        width: 100%; }

    .Steps > div > picture:nth-child(2) {
        width: 20.65vw;
        min-width: 20.65vw;
        height: 20.43vw;
        margin-right: 6.95vw; }

    .modalMenu > div {
        width: 90vw !important; } }


