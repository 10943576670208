@import "../../config.sass";

.About {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 1.31vw; }


.About > img {
    width: 100%;
    height: 34.16vw;
    margin-bottom: 6.56vw; }


.About > div:nth-child(2) {
    // width: 51.09vw
    width: 100%; }


.About > div:nth-child(2) > div > a {
    font-weight: bold;
    cursor: pointer;
    color: $blue-color;
    border-bottom: 0.1vw solid $blue-color; }


.About > div:nth-child(3) {
    width: 18.24vw; }


.About > div:nth-child(3) > article {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }


.About > div:nth-child(3) > article > div > h4 {
    font-size: 2.04vw;
    line-height: 2.48vw;
    color: black;
    font-weight: bold; }

.About > div:nth-child(2) > p,
.About > div:nth-child(3) > article > div > p {
    font-size: 1.31vw;
    line-height: 1.89vw; }

.About > div:nth-child(2) > p > span {
    cursor: pointer; }

.About > div:nth-child(2) > p > span > span {
    color: red; }

.About > div:nth-child(2) > p > span:hover,
.About > div:nth-child(2) > p > span:hover > span {
    color: $blue-color; }

.About > div:nth-child(3) > article > div > p {
    line-height: 2.04vw; }

.About > div:nth-child(3) > article:first-child > picture {
    width: 6.93vw; }

.About > div:nth-child(3) > article > picture > img {
    width: 100%; }

.About > div:nth-child(3) > article:last-child > img {
    width: 7.88vw; }

.info {
    padding: 4.96vw 10.21vw 2.91vw; }

.info {
    text-align: center; }


.info > p {
    margin: 0;
    font-size: 1.31vw;
    line-height: 2.04vw; }


.info > div {
    width: 13.43vw;
    margin: 2.62vw auto 0; }

@media (max-width: $tablet-width) {
    .About {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 2.47vw; }

    .About > img {
        height: 39.56vw;
        margin-bottom: 10.98vw; }


    .About > div:nth-child(2) {
        width: 100%; }


    .About > div:nth-child(2) > div > a {
        border-bottom: 0.1vw solid $blue-color; }


    .About > div:nth-child(3) {
        width: 100%;
        flex-direction: row;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 8vw; }


    .About > div:nth-child(3) > article {
        width: 40vw; }

    .About > div:nth-child(3) > article > div > h4 {
        font-size: 3.84vw;
        line-height: 4.67vw; }

    .About > div:nth-child(2) > p,
    .About > div:nth-child(3) > article > div > p {
        font-size: 2.47vw !important;
        line-height: 3.3vw; }


    .About > div:nth-child(3) > article > div > p {
        line-height: 3.3vw; }


    .About > div:nth-child(3) > article:first-child > picture {
        width: 13.04vw; }


    .About > div:nth-child(3) > article:last-child > img {
        width: 12.36vw; }

    .info {
        padding: 9.96vw 10.21vw 9.96vw; }


    .info > p {
        font-size: 2.47vw;
        line-height: 4.39vw; }


    .info > div {
        width: 40vw;
        margin: 7.62vw auto 0; } }


@media (max-width: $mobile-width) {
    .About {
        font-size: 3.91vw; }

    .About > img {
        height: 39.13vw;
        margin-bottom: 10.98vw; }


    .About > div:nth-child(2),
    .About > div:nth-child(3) {
        margin-bottom: 8.69vw; }


    .About > div:nth-child(2) > div > a {
        border-bottom: 0.2vw solid $blue-color; }

    .About > div:nth-child(3) {
        flex-direction: column; }

    .About > div:nth-child(3) > article {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        width: 100%; }

    .About > div:nth-child(3) > article > div {
        width: 100%; }

    .About > div:nth-child(3) > article > div > h4 {
        font-size: 6.08vw;
        line-height: 7.39vw; }

    .About > div:nth-child(2) > p,
    .About > div:nth-child(3) > article > div > p {
        font-size: 3.91vw !important;
        line-height: 5.21vw; }

    .About > div:nth-child(3) > article > img,
    .About > div:nth-child(3) > article > picture {
        margin-right: 9.56vw; }

    .About > div:nth-child(3) > article:first-child > picture {
        width: 20.65vw;
        min-width: 20.65vw; }


    .About > div:nth-child(3) > article:last-child > img {
        width: 20.65vw;
        min-width: 20.65vw; }

    .info {
        padding: 9.96vw 10.21vw 9.96vw; }


    .info > p {
        font-size: 3.91vw;
        line-height: 5.04vw; }


    .info > div {
        width: 40vw;
        margin: 7.62vw auto 0; } }


