@import "../../config.sass";

.form {
    display: flex;
    z-index: 20; }

.form :global(.ant-form-explain) {
    display: none; }

.form :global(.ant-form-item-control) {
    line-height: initial; }

.form :global(.has-error > .ant-form-item-children > .whiteInput) {
    box-shadow: 0 0 0 2px red; }

.form > div:last-child {
    width: 20.43vw; }

.form > div:last-child > p {
    text-align: center;
    color: white;
    margin-top: 0.87vw;
    font-size: 0.87vw; }

.form > div:last-child > p > span {
    text-decoration: none;
    border-bottom: 1px dashed white;
    cursor: pointer;
    transition: 0.2s; }


.form > div:last-child > p > span:hover {
    color: $blue-color;
    border-bottom: 1px dashed $blue-color; }

.disabledButton {
    pointer-events: none;
    opacity: 0.7; }


@media (max-width: $tablet-width) {
    .form {
        width: 61.12vw;
        flex-direction: column; }

    .form :global(.ant-form-explain) {
        display: none; }

    .form :global(.ant-form-item-control-wrapper) {
        width: 100%; }

    .form > * {
        width: 100% !important;
        margin: 0 !important; }

    .form > div:nth-child(2) {
        margin-top: 2.47vw !important; }


    .form > div:last-child > p {
        text-align: left;
        margin-top: 1.64vw;
        font-size: 1.64vw; }

    .form > div:last-child > p > span:hover {
        border-bottom: 1px dashed $blue-color; } }


@media (max-width: $mobile-width) {
    .form {
        width: 100%; }

    .form > div:nth-child(2) {
        margin-top: 3.47vw !important; }

    .form > div:last-child > p {
        margin-top: 2.6vw;
        font-size: 2.6vw; }

    .form > div:last-child > p > span {
        border-bottom: 1px dashed white; }

    .form > div:last-child > p > span:hover {
        border-bottom: 1px dashed $blue-color; } }
