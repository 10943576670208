@import "../../config.sass";

.Loans {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 32.7vw;
    margin: 8.02vw 0 0 0 !important;
    padding: 2.91vw 9.48vw 2.18vw;
    background-image: linear-gradient(rgba(32, 110, 178, 1) 50%, rgba(16,178,170,0.7));
    overflow: hidden; }

.Loans > picture {
    position: absolute;
    height: 22.62vw;
    right: -5.5vw;
    bottom: 4vw;
    z-index: 10; }

.Loans > picture > * {
    height: 100%;
    width: auto; }

.Loans > picture:first-child {
    display: none; }

.Loans > picture.action {
    height: 17.4vw;
    bottom: 14vw;
    right: 16vw; }

.lighter {
    height: 43.79vw;
    width: 58.39vw;
    position: absolute;
    bottom: -7vw;
    right: -16vw;
    background-image: radial-gradient(ellipse farthest-corner at 50% 50%, rgba(249, 249, 249, 1) -118px, rgba(80, 80, 80, 0) 56%);
    border-radius: 50%;
    z-index: 9; }


.Loans > h1 {
    z-index: 200;
    font-size: 5vw;
    line-height: 4.7vw;
    margin-bottom: 2.91vw;
    color: white; }


.Loans > ul {
    color: white;
    font-size: 2.04vw;
    margin-bottom: 2.91vw; }


.Loans > ul > li {
    display: flex;
    align-items: center; }


.Loans > ul > li > div {
    display: inline-block;
    background-color: white;
    width: 1.16vw;
    height: 0.29vw;
    margin-right: 1.89vw; }

@media (max-width: $tablet-width) {
    .Loans {
        margin-top: 15.1vw !important;
        min-height: 82.41vw;
        padding: 6.86vw 4.12vw;
        background-image: linear-gradient(rgba(32, 110, 178, 1) 50%, rgba(16,178,170,0.7));
        overflow: hidden; }

    .Loans > picture {
        width: auto;
        height: 26.82vw;
        right: -22vw;
        bottom: 12vw; }

    .Loans > picture.action {
        height: 25.4vw;
        bottom: 56vw;
        right: 4vw; }

    .lighter {
        position: absolute;
        bottom: 5vw;
        right: -28vw;
        background-image: radial-gradient(ellipse farthest-corner at 50% 50%, rgba(249, 249, 249, 1) -118px, rgba(80, 80, 80, 0) 56%);
        border-radius: 50%;
        z-index: 9; }

    .Loans > h1 {
        width: 80%;
        font-size: 9.89vw;
        line-height: 11vw;
        margin-bottom: 4.52vw;
        color: white; }

    .Loans > ul {
        font-size: 3.84vw;
        margin-bottom: 4.24vw; }

    .Loans > ul > li > div {
        width: 2.2vw;
        height: 0.4vw;
        margin-right: 1.89vw; } }


@media (max-width: $mobile-width) {
    .Loans {
        margin-top: 12.17vw !important;
        min-height: 130.43vw;
        padding: 7vw 4.12vw 50vw; }

    .Loans > picture:nth-child(2) {
        display: none; }

    .Loans > picture:first-child {
        display: initial;
        width: 100vw;
        height: auto;
        top: auto;
        right: 0;
        bottom: -20vw; }

    .Loans > picture:first-child > img {
        height: auto;
        width: 100%; }

    .Loans > picture.action {
        height: 38.4vw;
        bottom: 9vw;
        right: 50vw; }

    .lighter {
        bottom: -12vw;
        right: -28vw;
        height: 70vw;
        width: 121vw; }

    .Loans > h1 {
        width: 80%;
        font-size: 11vw;
        line-height: 11vw;
        margin-bottom: 6.95vw; }

    .Loans > ul {
        font-size: 3.91vw;
        margin-bottom: 6.52vw; }

    .Loans > ul > li > div {
        width: 3.47vw;
        height: 0.65vw;
        margin-right: 1.89vw; } }
