@import "../../config.sass";

.leftContent > ul {
    color: black;
    font-size: 2.04vw;
    margin-bottom: 5.1vw;
    padding-left: 3.5vw; }


.leftContent > ul > li {
    display: flex;
    align-items: center;
    margin-bottom: 0.43vw; }


.leftContent > ul > li > div {
    display: inline-block;
    background-color: #dcdcdc;
    width: 1.16vw;
    height: 0.29vw;
    margin-right: 1.89vw; }


.CreditInfo > .checkBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7vw; }

.CreditInfo > .checkBlock > article {
    width: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    font-size: 2.04vw;
    line-height: 2.62vw; }


.CreditInfo > .checkBlock > article > img {
    width: 2.91vw;
    height: 2.48vw;
    margin-bottom: 1.75vw; }


@media (max-width: $tablet-width) {
    .leftContent > ul {
        font-size: 3.84vw;
        margin-bottom: 6.04vw;
        padding-left: 0; }


    .leftContent > ul > li {
        margin-bottom: 0.82vw; }


    .leftContent > ul > li > div {
        width: 2.2vw;
        height: 0.54vw;
        margin-right: 3.57vw; }

    .CreditInfo > .checkBlock {
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap; }

    .CreditInfo > .checkBlock > article {
        width: 38vw;
        font-size: 3.84vw;
        line-height: 4.94vw;
        margin-bottom: 8.24vw; }


    .CreditInfo > .checkBlock > article > img {
        width: 7.14vw;
        height: 7.14vw;
        margin-bottom: 3.3vw; } }


@media (max-width: $mobile-width) {
    .leftContent > ul {
        font-size: 3.91vw;
        margin-bottom: 0;
        padding-bottom: 9.56vw;
        padding-left: 0;
        padding-right: 4vw;
        border-bottom: 0.86vw solid #e8e8e8; }

    .leftContent > ul > li {
        margin-bottom: 1.73vw; }

    .leftContent > ul > li > div {
        width: 3.47vw;
        min-width: 3.47vw;
        height: 0.86vw;
        margin-right: 6.08vw; }

    .CreditInfo > .checkBlock {
        margin-top: 9.56vw;
        flex-direction: column;
        align-items: start;
        flex-wrap: wrap; }

    .CreditInfo > .checkBlock > article {
        flex-direction: row;
        align-items: flex-end;
        width: 100%;
        font-size: 3.84vw;
        line-height: 4.94vw;
        margin-bottom: 3.47vw; }

    .CreditInfo > .checkBlock > article > img {
        width: 4.78vw;
        min-width: 4.78vw;
        height: 4.78vw;
        margin-right: 5.21vw;
        margin-bottom: 0; } }


