@import "../../config.sass";

.docsPanel > article > span {
  font-size: 1.15vw; }


.docsPanel {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: flex-start;
  padding-bottom: 1.34vw;
  margin-bottom: 2.89vw;
  border-bottom: 0.15vw solid rgba(9, 179, 170, 0.1); }


.docsPanel > article {
  display: flex;
  width: 13.91vw;
  height: 30vw;
  justify-content: space-between;
  align-items: center;
  flex-direction: column; }


.docsPanel > article > div {
  position: relative;
  cursor: pointer;
  margin-bottom: 2vw; }


.docsPanel > article > div > img {
  width: 100%;
  transition: 0.1s ease-in; }


.docsPanel > article > div > .shower {
  position: absolute;
  opacity: 0;
  transition: 0.1s ease-in;
  z-index: 10;
  width: 2.89vw;
  height: 2.89vw;
  left: calc(100% / 2 - 1.445vw);
  top: calc(100% / 2 - 1.445vw); }


.docsPanel > article > div:hover > img:last-child {
  opacity: 0.5; }


.docsPanel > article > div:hover > .shower {
  opacity: 1; }


.docsPanel > article > span {
  flex: 1; }


.modalDocument {
  width: 48vw !important; }


.modalDocument :global(.ant-modal-body) {
  padding: 4.34vw 4.8vw !important; }


.document > h1 {
  text-align: center;
  font-size: 2.6vw;
  line-height: 3.15vw;
  margin: 0 -1vw 2.75vw; }


.document > img {
  width: 100%; }


@media (max-width: $tablet-width) {
  .docs {
    padding: 0 3.75%;
    margin: 6vw 0; }


  .docs > h2 {
    font-size: 3vw; }


  .docsPanel > article > span {
    font-size: 2vw; }


  .docsPanel > article > span {
    line-height: 2.5vw; }


  .docsPanel {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0;
    margin-bottom: 5vw;
    border-bottom: 0.25vw solid rgba(9, 179, 170, 0.1); }


  .docsPanel > article {
    width: 32vw;
    padding: 0 3.5vw;
    height: 47.625vw;
    margin-bottom: 5vw; }


  .docsPanel > article > div > .shower {
    width: 5vw;
    height: 5vw;
    left: calc(100% / 2 - 2.5vw);
    top: calc(100% / 2 - 2.5vw); }


  .docsPanel > article > span {
    height: 9.375vw; }


  .modalDocument {
    width: 80vw !important; }


  .modalDocument :global(.ant-modal-body) {
    padding: 4vw 5vw !important; }


  .document > h1 {
    font-size: 4.6vw;
    line-height: 5.2vw;
    margin-bottom: 5vw; } }



@media (max-width: $mobile-width) {

  .docsPanel {
    border-bottom: 0.43vw solid rgba(9, 179, 170, 0.1);
    padding-bottom: 4vw;
    margin-bottom: 10vw; }


  .docsPanel > article > div > .shower {
    width: 8.69vw;
    height: 8.69vw;
    left: calc(100% / 2 - 4.345vw);
    top: calc(100% / 2 - 4.345vw); }


  .docsPanel > article > span {
    font-size: 3.47vw; }


  .docsPanel > article > span {
    line-height: 4.5vw; }


  .docsPanel > article {
    width: 40.34vw;
    height: 77.82vw;
    padding: 0;
    margin-bottom: 8.69vw; }


  .docsPanel > article > span {
    height: 16.3vw; }


  .modalDocument {
    width: 91vw !important; }


  .modalDocument :global(.ant-modal-body) {
    padding: 6.95vw 8.69vw !important; }


  .document > h1 {
    font-size: 7.82vw;
    line-height: 8.3vw;
    margin-bottom: 8.69vw; } }


