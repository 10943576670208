@import "../../config.sass";

.Info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8.46vw;
    flex-direction: column; }


/*.Info :global(p)
    margin: 0
    font-size: 0.8vw
    color: #767676*/

.condition p {
    text-align: justify;
    margin: 0;
    font-size: 0.8vw;
    color: #767676; }

//.Info > div:first-child
//    width: 34vw


//.Info > div:last-child
//    width: 33.57vw


.Info > div:first-child > article {
     margin-bottom: 3.35vw; }

.Info > div:first-child > article:last-child {
    margin-bottom: 0; }

.Info > div:first-child > article > h4,
.fileList > h4 {
    color: black;
    font-size: 2.04vw;
    line-height: 2.62vw;
    margin-bottom: 1.6vw; }

.fileList > ul > li {
    margin-bottom: 0.43vw; }

.fileList > ul > li,
.fileList > ul > li:last-child > div {
    display: flex; }


.fileList > ul > li > img,
.fileList > ul > li:last-child > div > img {
    width: 0.94vw;
    min-width: 0.94vw;
    height: 1.16vw;
    margin-top: 0.43vw;
    margin-right: 1.16vw; }


.fileList > ul > li > a,
.fileList > ul > li > div > a,
.fileList > ul > li > p {
    font-size: 1.16vw; }


.fileList > ul > li > a,
.fileList > ul > li:last-child > div > a {
    line-height: 2.18vw;
    display: inline;
    color: black; }


.fileList > ul > li > a > span,
.fileList > ul > li:last-child > div > a > span {
    margin-right: 0.43vw;
    color: #005ba8;
    border-bottom: 0.1vw solid #005ba8; }


.fileList > ul > li:last-child {
    margin-top: 4.08vw;
    flex-direction: column;
    align-items: flex-start; }

.fileList ul {
    column-count: 2;
    margin-bottom: 5vw; }

@media (max-width: $tablet-width) {
    .fileList ul {
        column-count: 2;
        margin-bottom: 5vw; }

    .Info {
        margin-bottom: 10vw;
        flex-direction: column; }


    .Info :global(p) {
        margin: 0;
        font-size: 1.47vw; }


    .Info > div:first-child {
        width: 100%; }


    .Info > div:last-child {
        width: 100%; }


    .Info > div:first-child > article {
        margin-bottom: 6.04vw; }


    .Info > div:first-child > article > h4,
    .fileList > h4 {
        font-size: 3.84vw;
        line-height: 4.94vw;
        margin-bottom: 3.02vw; }

    .fileList {
        margin-top: 6.04vw; }

    .fileList > ul > li {
        margin-bottom: 0.82vw; }

    .fileList > ul > li > img,
    .fileList > ul > li:last-child > div > img {
        width: 1.78vw;
        min-width: 1.78vw;
        height: 2.2vw;
        margin-top: 0.82vw;
        margin-right: 2.2vw; }


    .fileList > ul > li > a,
    .fileList > ul > li > div > a,
    .fileList > ul > li > p {
        font-size: 2.2vw; }


    .fileList > ul > li > a,
    .fileList > ul > li:last-child > div > a {
        line-height: 4.12vw; }


    .fileList > ul > li > a > span,
    .fileList > ul > li:last-child > div > a > span {
        margin-right: 0.82vw;
        border-bottom: 0.1vw solid $blue-color; }


    .fileList > ul > li:last-child {
        margin-top: 4vw;
        flex-direction: column;
        align-items: flex-start; } }


@media (max-width: $mobile-width) {
    .fileList ul {
        column-count: 1;
        margin-bottom: 5vw; }

    .Info {
        margin-bottom: 14vw; }

    .Info :global(p) {
        font-size: 2.6vw; }

    .Info > div:first-child > article {
        margin-bottom: 9.56vw; }


    .Info > div:first-child > article > h4,
    .fileList > h4 {
        font-size: 6.08vw;
        line-height: 7.82vw;
        margin-bottom: 4.78vw; }

    .fileList {
        margin-top: 9.56vw; }

    .fileList > ul > li {
        margin-bottom: 1.3vw; }

    .fileList > ul > li > img,
    .fileList > ul > li:last-child > div > img {
        width: 2.82vw;
        min-width: 2.82vw;
        height: 3.47vw;
        margin-top: 1.3vw;
        margin-right: 3.47vw; }


    .fileList > ul > li > a,
    .fileList > ul > li > div > a,
    .fileList > ul > li > p {
        font-size: 3.47vw; }


    .fileList > ul > li > a,
    .fileList > ul > li:last-child > div > a {
        line-height: 6.52vw; }


    .fileList > ul > li > a > span,
    .fileList > ul > li:last-child > div > a > span {
        margin-right: 1.3vw;
        border-bottom: 0.2vw solid $blue-color; }


    .fileList > ul > li:last-child {
        margin-top: 7.39vw; } }


.companyInfo {
  display: flex;
  justify-content: space-between;
  /*grid-column-gap: 190px*/
  margin-top: 35px;
  margin-bottom: 35px; }

.companyInfo > div:first-child {
  width: 34vw;
  display: flex;
  flex-direction: column; }

.companyInfo > div:first-child > img {
  width: 80%;
  // height: 94px
  margin-bottom: 15px; }

.companyTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  font-family: OpenSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;

 }  // width: 100%

.companyInfo > div:last-child {
  width: 34vw; }

.companyInfo > div:last-child p {
  font-family: OpenSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.16vw;
  // line-height: 25px
  margin-bottom: 0; }

.companyInfo > div:last-child > p:nth-child(1) {
 }  // width: 639px

.companyInfo > div:last-child > p:nth-child(2) {
 }  // width: 639px

.companyInfo > div:last-child > p:nth-child(3) {
 }  // width: 546px

@media (max-width: $tablet-width) {
  .companyInfo {
    flex-direction: column;
    margin-bottom: 0; }

  .companyInfo > div {
    width: 100%!important;
    margin-bottom: 35px; }
  .companyInfo > div:first-child > img {}


  .companyInfo > div:last-child p {
    font-size: 2.47vw; } }


@media (max-width: $mobile-width) {
  .companyInfo > div:last-child p {
    font-size: 3.91vw; } }





.docsBlock {
  display: flex;
  align-items: flex-start;
  grid-column-gap: 86px;
  margin-bottom: 40px; }

.docsList {
  display: flex;
  flex-direction: column;
  width: 50%;
  grid-row-gap: 6px;
  margin-bottom: 0; }

.docsList li {
  /*display: flex*/
  /*align-items: center*/
  max-width: 520px; }

.docsList li > a {
  display: flex;
  align-items: baseline; }

.docsList li img {
  width: 13px;
  height: 16px;
  margin-right: 15.5px; }

.docsList li span {
  max-width: 490px;
  font-family: OpenSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline; }

.docsSubList {
  display: none;
  margin-left: 30px;
  margin-top: 6px; }

@media (max-width: $tablet-width) {
  .docsBlock {
    flex-direction: column; }

  .docsList {
    width: 100%; } }


h1.financeInfoTitle, h1.sroInfoTitle, h1.clientsInfoTitle {
    font-size: 1.5em;
    margin-top: 0;
    margin-bottom: .5em;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-family: OpenSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif; }

.infoTable {
  display: none;
  width: 100%;
  margin-bottom: 33px; }

.infoTable tr {
  vertical-align: baseline; }

.infoTable > tr:not(:last-child) td {
  padding-bottom: 5px; }

.infoTable > tr td:first-child {
  width: 60%;
  padding-right: 40px; }

.infoTable > tr td:last-child {
  /*width: 497px*/ }

.footNote {
  width: 100%;
  // height: 200px
  margin-top: 28px; }

.footNote p {
    text-align: justify;
    margin: 0;
    // font-size: 0.8vw
    font-size: 8px;
    color: #767676;
    font-family: OpenSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif; }

.infoListGroup {
  display: flex;
  justify-content: space-between;
  grid-column-gap: 86px;
  margin-bottom: 40px; }

.infoListGroup > .infoListBlock {
  width: 50%; }

.infoLastBlock {
  margin-bottom: 100px; }

.infoList > li, .infoLastBlock > p {
    margin-bottom: 8px;
    font-size: 1.16vw;
    font-family: OpenSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif; }

@media (max-width: $tablet-width) {
  .infoListGroup {
    flex-wrap: wrap; }

  .infoListGroup > .infoListBlock {
    width: 100%; }

  .infoListGroup > .infoListBlock:not(:first-child) {
    margin-top: 6.04vw; }

  .infoList > li, .infoLastBlock > p {
    font-size: 2.47vw; } }


@media (max-width: $mobile-width) {
  .infoList > li, .infoLastBlock > p {
    font-size: 3.91vw; } }

