@import "../../config.sass";

.ServiceBy {
    font-style: italic;
    padding-top: 3vw;
    font-size: 0.9vw; }

.ServiceByTablet {
    font-style: italic;
    padding-top: 3vw;
    font-size: 0.9vw;
    display: none; }


.CreditTermItem {
    display: flex;
    flex-direction: column;
    width: 20.14vw;
    margin-right: 6.56vw; }

.CreditTermItem :global(.calcExample) {
    font-size: 0.8vw;
    line-height: 0.9vw;
    color: #767676; }

.CreditTermItem button {
    margin-bottom: 2vw;
    margin-top: 2vw; }

.CreditTermItem :global(.ant-input-number) {
    border: 0;
    height: auto; }

.CreditTermItem :global(.ant-input-number-focused) {
    box-shadow: initial; }

.CreditTermItem :global(.ant-input-number-input),
.CreditTermItem :global(.ant-form) {
    font-size: 3.28vw;
    white-space: nowrap;
    height: auto;
    padding: 0; }

.CreditTermItem :global(.ant-slider-mark-text) {
    transform: translateX(-100%) !important;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.45);
    padding-top: 1vw; }

.CreditTermItem :global(.ant-slider-mark-text):first-child {
    transform: translateX(0%) !important; }

.CreditTermItem > h4 {
    color: black;
    margin-top: 1vw;
    font-size: 1.31vw;
    line-height: 2.60vw; }

.CreditTermItem > div {
    width: 100%; }

.slider {
    width: 100%; }

.CreditTermItem > div > span {
    display: inline-block;
    color: black;
    margin-top: 0.72vw;
    font-size: 1.31vw;
    line-height: 1.6vw;
    transition: 0s; }


.linkLabel {
    cursor: pointer;
    color: #767676 !important;
    border-bottom: 0.1vw solid #767676;
    transition: 0.2s; }


.linkLabel:hover {
    color: black !important;
    border-bottom: 0.1vw solid black; }

.finishCredit {
    margin-top: 2vw !important; }

.finishCredit2 {
    display: none !important; }

@media (max-width: $tablet-width) {
    .finishCredit {
        display: none !important; }

    .finishCredit2 {
        display: block !important;
        color: #000 !important;
        margin-bottom: 2vw !important;
        margin-top: 2vw !important; }

    .CreditTermItem :global(.calcExample) {
        font-size: 2.0vw;
        line-height: 2.3vw; }

    .CreditTermItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-right: 0; }


    .CreditTermItem > h4 {
        margin: 0;
        margin-top: 3vw;
        min-width: 37.91vw;
        font-size: 2.84vw;
        line-height: 4.94vw; }


    .CreditTermItem > div {
        width: 100%; }

    .CreditTermItem :global(.ant-input-number-input),
    .CreditTermItem :global(.ant-form) {
        font-size: 6.18vw; }

    .slider {
        width: 100%; }


    .CreditTermItem > div > span {
        //width: 37vw
        color: #767676;
        margin-top: 1.37vw;
        font-size: 2.47vw;
        line-height: 3.02vw; }

    .linkLabel,
    .inactiveLink,
    .CreditTermItem :global(.disabledLink) {
        display: none !important; }

    .ServiceByTablet {
        display: block !important; }

    .ServiceBy {
        display: none; } }


@media (max-width: $mobile-width) {
    .finishCredit {
        display: none !important; }

    .finishCredit2 {
        display: block !important;
        color: #000 !important;
        margin-bottom: 2vw !important;
        margin-top: 2vw !important; }


    .CreditTermItem :global(.calcExample) {
        font-size: 3vw;
        line-height: 3vw;
        color: #767676; }

    .CreditTermItem {
        display: flex;
        flex-direction: column;
        align-items: end; }

    .CreditTermItem > h4 {
        margin: 0;
        font-size: 3.91vw;
        line-height: 6.08vw; }

    .CreditTermItem :global(.ant-input-number-input),
    .CreditTermItem :global(.ant-form) {
        font-size: 7.82vw; }

    .CreditTermItem > div > span {
        //width: 58vw
        margin-top: 2.17vw;
        font-size: 3.91vw;
        line-height: 4.78vw; }

    .slider {
        width: 96%;
        margin: 0 auto; }


    .ServiceBy {
        font-size: 3vw;
        display: block !important; }

    .ServiceByTablet {
        display: none !important; } }
