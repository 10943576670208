@import "../../config.sass";

.Success {
   padding: 4.96vw 10.21vw 2.91vw; }


.Success {
   text-align: center; }


.Success > h2 {
   font-size: 3.35vw;
   color: black;
   font-weight: bold;
   line-height: 4.37vw;
   padding: 0;
   margin: 0 0 1.45vw 0; }


.Success > h4 {
   font-size: 1.89vw;
   color: black;
   font-weight: bold;
   line-height: 2.62vw;
   padding: 0;
   margin: 0 0 1.45vw 0; }


.Success > p {
   margin: 0;
   font-size: 1.31vw;
   line-height: 2.04vw; }


.Success > div {
   width: 13.43vw;
   margin: 2.62vw auto 0; }


@media (max-width: $tablet-width) {
   .Success {
      padding: 6.86vw 7.69vw; }

   .Success > h2 {
      font-size: 6.04vw;
      line-height: 7.6vw;
      margin: 0 0 2.74vw 0; }


   .Success > h4 {
      font-size: 3.57vw;
      line-height: 4.94vw;
      margin: 0 0 2.74vw 0; }


   .Success > p {
      font-size: 2.47vw;
      line-height: 3.84vw; }


   .Success > div {
      width: 25.27vw;
      margin: 4.94vw auto 0; } }


@media (max-width: $mobile-width) {
   .Success {
      padding: 10vw 10vw; }

   .Success > h2 {
      font-size: 7.82vw;
      line-height: 9vw;
      margin: 0 0 4.34vw 0; }


   .Success > h4 {
      font-size: 4.34vw;
      line-height: 5.65vw;
      margin: 0 0 4.34vw 0; }


   .Success > p {
      font-size: 3.91vw;
      line-height: 6.08vw; }


   .Success > div {
      width: 42vw;
      margin: ovw auto 0; } }
