@import "../../config.sass";

.DataAgreement {
   padding: 4.96vw 10.21vw 2.91vw; }


.DataAgreement {
   text-align: center; }


.DataAgreement > h2 {
   font-size: 3.35vw;
   color: black;
   font-weight: bold;
   line-height: 4.37vw;
   padding: 0;
   margin: 0 0 1.75vw 0; }


.DataAgreement > p {
   margin: 0;
   font-size: 1.31vw;
   line-height: 2.04vw; }


.DataAgreement > div {
   width: 13.43vw;
   margin: 2.62vw auto 0; }


@media (max-width: $tablet-width) {
   .DataAgreement {
      padding: 9.96vw 10.21vw 9.96vw; }


   .DataAgreement > h2 {
      font-size: 6.04vw;
      line-height: 7.5vw;
      padding: 0 1vw;
      margin: 0 0 6.12vw 0; }


   .DataAgreement > p {
      font-size: 2.47vw;
      line-height: 4.39vw; }


   .DataAgreement > div {
      width: 40vw;
      margin: 7.62vw auto 0; } }


@media (max-width: $mobile-width) {
   .DataAgreement {
      padding: 9.96vw 10.21vw 9.96vw; }


   .DataAgreement > h2 {
      font-size: 6.04vw;
      line-height: 7.5vw;
      padding: 0 1vw;
      margin: 0 0 6.12vw 0; }


   .DataAgreement > p {
      font-size: 3.91vw;
      line-height: 5.04vw; }


   .DataAgreement > div {
      width: 40vw;
      margin: 7.62vw auto 0; } }
