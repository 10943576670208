@import "../../config.sass";

.SectionWrapper {
    padding-top: 6.02vw; }

.SectionWrapper > h2 {
    font-size: 3.35vw;
    color: black;
    font-weight: bold;
    line-height: 3.64vw;
    padding: 0;
    margin: 0 0 4.37vw 0; }

@media (max-width: $tablet-width) {
    .SectionWrapper {
        padding-top: 7.55vw; }

    .SectionWrapper > h2 {
        font-size: 6.18vw;
        line-height: 7.5vw;
        margin: 0 0 6.04vw 0; } }


@media (max-width: $mobile-width) {
    .SectionWrapper {
        padding-top: 10vw; }

    .SectionWrapper > h2 {
        font-size: 7.82vw;
        line-height: 9.56vw;
        margin: 0 0 6.52vw 0; } }
