.Header_Header__SM7bj {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 8.02vw;
  z-index: 210;
  margin: 0 !important;
  padding: 0 9.48vw; }

.Header_showMargin__vjmWN {
  top: 4.34vw; }

.Header_Header__SM7bj > .Header_logo__-gBR- {
  width: 17vw; }

.Header_logo__-gBR- > picture > img {
  width: 100%;
  height: auto;
  cursor: pointer; }

.Header_Header__SM7bj > ul {
  display: flex;
  justify-content: space-between;
  width: 28vw;
  transition: 0.2s; }

.Header_menuList__2w57J > li > a,
.Header_Header__SM7bj > ul > li > a {
  color: black;
  transition: 0.2s; }

.Header_Header__SM7bj > ul > li > a {
  font-size: 1.31vw; }

.Header_menuList__2w57J > li > a:hover,
.Header_Header__SM7bj > ul > li > a:hover {
  color: #005ba8; }

.Header_contacts__1_K1n {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.Header_contacts__1_K1n > div:first-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 16.93vw;
  font-size: 1.89vw; }

.Header_contacts__1_K1n > div:first-child > img {
  width: 1.09vw;
  height: 1.45vw;
  margin-right: 0.87vw; }

.Header_contacts__1_K1n > div:first-child > a {
  cursor: pointer;
  color: #000;
  text-decoration: none;
  line-height: 2.48vw; }

.Header_contacts__1_K1n > div:last-child {
  font-size: 0.87vw;
  color: #767676; }

.Header_menu__m2jnu {
  display: none; }

.Header_headerButton__2vrAP {
  opacity: 0;
  width: 13.5vw !important;
  height: 2.62vw !important;
  font-size: 1.02vw !important;
  line-height: 2.62vw !important; }

.Header_modalMenu__21qii > div {
  width: 40.43vw !important; }

.Header_stickyHeader__1i4Rn {
  position: fixed;
  top: 0;
  width: 100% !important;
  margin: 0 !important;
  background-color: white;
  transition: 0.2s, color 0.1s ease-in, background-color 0.1s ease-in; }

.Header_stickyHeader__1i4Rn > .Header_headerButton__2vrAP {
  display: initial; }

.Header_stickyHeader__1i4Rn > ul {
  width: 22vw; }

.Header_stickyHeader__1i4Rn > ul > li > a {
  font-size: 1.02vw; }

@media (max-width: 900px) {
  .Header_Header__SM7bj {
    height: 15.1vw;
    padding: 0 4.12vw; }
  .Header_showMargin__vjmWN {
    top: 7.5vw; }
  .Header_Header__SM7bj > .Header_logo__-gBR- {
    width: 32.14vw; }
  .Header_Header__SM7bj > ul,
  .Header_Header__SM7bj > .Header_headerButton__2vrAP {
    display: none; }
  .Header_contacts__1_K1n > div:first-child {
    min-width: 32.14vw;
    font-size: 3.84vw; }
  .Header_contacts__1_K1n > div:first-child > img {
    width: 2.06vw;
    height: 2.74vw;
    margin-right: 1.64vw; }
  .Header_contacts__1_K1n > div:first-child > a {
    line-height: 4.67vw; }
  .Header_contacts__1_K1n > div:last-child {
    font-size: 1.64vw; }
  .Header_menu__m2jnu {
    display: initial;
    cursor: pointer; }
  .Header_menuIcon__3KBtd {
    height: 13px;
    width: 3.57vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .Header_menuIcon__3KBtd > * {
    height: 3px;
    width: 100%;
    background-color: black; }
  .Header_modalMenu__21qii > div,
  .Header_stickyModalMenu__3f8x7 > div {
    width: 59vw !important;
    margin: 0 !important;
    right: 4.12vw;
    top: 11.53vw;
    position: absolute; }
  .Header_showModalMargin__307NO > div {
    top: 19.03vw; }
  .Header_stickyModalMenu__3f8x7 > div {
    top: 8vw; }
  .Header_close__1Ck0l {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: -6vw;
    width: 3.57vw;
    height: 3.57vw; }
  .Header_close__1Ck0l:hover {
    opacity: 1; }
  .Header_close__1Ck0l:before, .Header_close__1Ck0l:after {
    position: absolute;
    left: 1.37vw;
    content: ' ';
    height: 3.57vw;
    width: 0.4vw;
    background-color: white; }
  .Header_close__1Ck0l:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .Header_close__1Ck0l:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .Header_menuList__2w57J > li {
    padding: 0 4.94vw;
    height: 6.86vw;
    font-size: 2.47vw;
    line-height: 6.86vw;
    border-bottom: 0.4vw solid #f1f1f1; }
  .Header_menuList__2w57J > li:last-child {
    border-bottom: 0; }
  .Header_headerButton__2vrAP {
    display: none; }
  .Header_stickyHeader__1i4Rn {
    height: 7.55vw; }
  .Header_stickyHeader__1i4Rn > .Header_contacts__1_K1n > div:last-child {
    display: none; } }

@media (max-width: 460px) {
  .Header_Header__SM7bj {
    height: 12.17vw; }
  .Header_showMargin__vjmWN {
    top: 13.04vw; }
  .Header_Header__SM7bj > .Header_logo__-gBR- {
    width: 33.91vw; }
  .Header_contacts__1_K1n > div:first-child {
    min-width: 34.78vw;
    font-size: 3.91vw; }
  .Header_contacts__1_K1n > div:first-child > img {
    width: 2.39vw;
    height: 3.04vw;
    margin-right: 2.17vw; }
  .Header_contacts__1_K1n > div:first-child > a {
    line-height: 4.67vw; }
  .Header_contacts__1_K1n > div:last-child {
    display: none; }
  .Header_close__1Ck0l {
    top: -9vw;
    width: 4.78vw;
    height: 4.78vw; }
  .Header_close__1Ck0l:hover {
    opacity: 1; }
  .Header_close__1Ck0l:before, .Header_close__1Ck0l:after {
    left: 2.17vw;
    content: ' ';
    height: 5.65vw;
    width: 0.6vw; }
  .Header_menuIcon__3KBtd {
    width: 5.65vw; }
  .Header_modalMenu__21qii > div,
  .Header_stickyModalMenu__3f8x7 > div {
    width: 93.47vw !important;
    top: 12.17vw;
    position: absolute; }
  .Header_showModalMargin__307NO > div {
    top: 25.21vw; }
  .Header_menuList__2w57J > li {
    padding: 0 4.94vw;
    height: 10.86vw;
    line-height: 10.86vw;
    font-size: 3.91vw;
    border-bottom: 0.6vw solid #f1f1f1; }
  .Header_menuList__2w57J > li:last-child {
    border-bottom: 0; } }

.Button_button__1DC4c {
  text-align: center;
  line-height: 3.94vw;
  font-size: 1.31vw;
  font-weight: bold;
  width: 100%;
  border-radius: 0.72vw;
  height: 3.94vw;
  border-width: 0;
  color: white;
  transition: 0.2s; }

.Button_button__1DC4c:not(.Button_noblick_btn__KUKIT) {
  position: relative;
  overflow-x: hidden; }

.Button_button__1DC4c:not(.Button_noblick_btn__KUKIT):after {
  content: '';
  position: absolute;
  top: 0;
  left: -3em;
  width: 20px;
  height: 100%;
  background-color: #fff;
  opacity: 0.4;
  -webkit-transform: skewX(-45deg);
          transform: skewX(-45deg);
  -webkit-animation-name: Button_moveBlick__Y8pqy;
          animation-name: Button_moveBlick__Y8pqy;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-delay: 10s;
          animation-delay: 10s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

@-webkit-keyframes Button_moveBlick__Y8pqy {
  0% {
    -webkit-transform: translateX(-3em) skewX(-45deg);
            transform: translateX(-3em) skewX(-45deg); }
  80% {
    -webkit-transform: translateX(-3em) skewX(-45deg);
            transform: translateX(-3em) skewX(-45deg); }
  100% {
    -webkit-transform: translateX(20em) skewX(-45deg);
            transform: translateX(20em) skewX(-45deg); } }

@keyframes Button_moveBlick__Y8pqy {
  0% {
    -webkit-transform: translateX(-3em) skewX(-45deg);
            transform: translateX(-3em) skewX(-45deg); }
  80% {
    -webkit-transform: translateX(-3em) skewX(-45deg);
            transform: translateX(-3em) skewX(-45deg); }
  100% {
    -webkit-transform: translateX(20em) skewX(-45deg);
            transform: translateX(20em) skewX(-45deg); } }

@media (max-width: 460px) {
  @-webkit-keyframes Button_moveBlick__Y8pqy {
    0% {
      -webkit-transform: translateX(0) skewX(-45deg);
              transform: translateX(0) skewX(-45deg); }
    80% {
      -webkit-transform: translateX(0) skewX(-45deg);
              transform: translateX(0) skewX(-45deg); }
    100% {
      -webkit-transform: translateX(100vw) skewX(-45deg);
              transform: translateX(100vw) skewX(-45deg); } }
  @keyframes Button_moveBlick__Y8pqy {
    0% {
      -webkit-transform: translateX(0) skewX(-45deg);
              transform: translateX(0) skewX(-45deg); }
    80% {
      -webkit-transform: translateX(0) skewX(-45deg);
              transform: translateX(0) skewX(-45deg); }
    100% {
      -webkit-transform: translateX(100vw) skewX(-45deg);
              transform: translateX(100vw) skewX(-45deg); } } }

.Button_button__1DC4c:hover, .Button_default__1hSgZ:active, .Button_default__1hSgZ:focus {
  color: white; }

.Button_default__1hSgZ, .Button_default__1hSgZ:visited {
  background-color: #06ae35; }

.Button_default__1hSgZ:hover, .Button_default__1hSgZ:active, .Button_default__1hSgZ:focus {
  background-color: #00c837; }

.Button_orange__1cifN, .Button_orange__1cifN:visited {
  background-color: #f14f00; }

.Button_orange__1cifN:hover, .Button_orange__1cifN:active, .Button_orange__1cifN:focus {
  background-color: #ff8023; }

.Button_yellow__2dsiA, .Button_yellow__2dsiA:visited {
  background-color: #e9f40c;
  color: black; }

.Button_yellow__2dsiA:hover, .Button_yellow__2dsiA:active, .Button_yellow__2dsiA:focus {
  background-color: #f4ff16;
  color: black; }

@media (max-width: 900px) {
  .Button_button__1DC4c {
    border-radius: 1.37vw;
    height: 7.41vw;
    line-height: 7.41vw;
    font-size: 2.47vw; } }

@media (max-width: 460px) {
  .Button_button__1DC4c {
    border-radius: 1.37vw;
    height: 7.41vw;
    line-height: 7.41vw;
    font-size: 2.47vw; } }

@media (max-width: 460px) {
  .Button_button__1DC4c {
    border-radius: 2.17vw;
    height: 11.73vw;
    line-height: 11.73vw;
    font-size: 3.91vw; } }

.InputForm_form__2PYRW {
  display: flex;
  z-index: 20; }

.InputForm_form__2PYRW .ant-form-explain {
  display: none; }

.InputForm_form__2PYRW .ant-form-item-control {
  line-height: initial; }

.InputForm_form__2PYRW .has-error > .ant-form-item-children > .whiteInput {
  box-shadow: 0 0 0 2px red; }

.InputForm_form__2PYRW > div:last-child {
  width: 20.43vw; }

.InputForm_form__2PYRW > div:last-child > p {
  text-align: center;
  color: white;
  margin-top: 0.87vw;
  font-size: 0.87vw; }

.InputForm_form__2PYRW > div:last-child > p > span {
  text-decoration: none;
  border-bottom: 1px dashed white;
  cursor: pointer;
  transition: 0.2s; }

.InputForm_form__2PYRW > div:last-child > p > span:hover {
  color: #005ba8;
  border-bottom: 1px dashed #005ba8; }

.InputForm_disabledButton__aX792 {
  pointer-events: none;
  opacity: 0.7; }

@media (max-width: 900px) {
  .InputForm_form__2PYRW {
    width: 61.12vw;
    flex-direction: column; }
  .InputForm_form__2PYRW .ant-form-explain {
    display: none; }
  .InputForm_form__2PYRW .ant-form-item-control-wrapper {
    width: 100%; }
  .InputForm_form__2PYRW > * {
    width: 100% !important;
    margin: 0 !important; }
  .InputForm_form__2PYRW > div:nth-child(2) {
    margin-top: 2.47vw !important; }
  .InputForm_form__2PYRW > div:last-child > p {
    text-align: left;
    margin-top: 1.64vw;
    font-size: 1.64vw; }
  .InputForm_form__2PYRW > div:last-child > p > span:hover {
    border-bottom: 1px dashed #005ba8; } }

@media (max-width: 460px) {
  .InputForm_form__2PYRW {
    width: 100%; }
  .InputForm_form__2PYRW > div:nth-child(2) {
    margin-top: 3.47vw !important; }
  .InputForm_form__2PYRW > div:last-child > p {
    margin-top: 2.6vw;
    font-size: 2.6vw; }
  .InputForm_form__2PYRW > div:last-child > p > span {
    border-bottom: 1px dashed white; }
  .InputForm_form__2PYRW > div:last-child > p > span:hover {
    border-bottom: 1px dashed #005ba8; } }

.Success_Success__3Jdnc {
  padding: 4.96vw 10.21vw 2.91vw; }

.Success_Success__3Jdnc {
  text-align: center; }

.Success_Success__3Jdnc > h2 {
  font-size: 3.35vw;
  color: black;
  font-weight: bold;
  line-height: 4.37vw;
  padding: 0;
  margin: 0 0 1.45vw 0; }

.Success_Success__3Jdnc > h4 {
  font-size: 1.89vw;
  color: black;
  font-weight: bold;
  line-height: 2.62vw;
  padding: 0;
  margin: 0 0 1.45vw 0; }

.Success_Success__3Jdnc > p {
  margin: 0;
  font-size: 1.31vw;
  line-height: 2.04vw; }

.Success_Success__3Jdnc > div {
  width: 13.43vw;
  margin: 2.62vw auto 0; }

@media (max-width: 900px) {
  .Success_Success__3Jdnc {
    padding: 6.86vw 7.69vw; }
  .Success_Success__3Jdnc > h2 {
    font-size: 6.04vw;
    line-height: 7.6vw;
    margin: 0 0 2.74vw 0; }
  .Success_Success__3Jdnc > h4 {
    font-size: 3.57vw;
    line-height: 4.94vw;
    margin: 0 0 2.74vw 0; }
  .Success_Success__3Jdnc > p {
    font-size: 2.47vw;
    line-height: 3.84vw; }
  .Success_Success__3Jdnc > div {
    width: 25.27vw;
    margin: 4.94vw auto 0; } }

@media (max-width: 460px) {
  .Success_Success__3Jdnc {
    padding: 10vw 10vw; }
  .Success_Success__3Jdnc > h2 {
    font-size: 7.82vw;
    line-height: 9vw;
    margin: 0 0 4.34vw 0; }
  .Success_Success__3Jdnc > h4 {
    font-size: 4.34vw;
    line-height: 5.65vw;
    margin: 0 0 4.34vw 0; }
  .Success_Success__3Jdnc > p {
    font-size: 3.91vw;
    line-height: 6.08vw; }
  .Success_Success__3Jdnc > div {
    width: 42vw;
    margin: ovw auto 0; } }

.DataAgreement_DataAgreement__1i7_e {
  padding: 4.96vw 10.21vw 2.91vw; }

.DataAgreement_DataAgreement__1i7_e {
  text-align: center; }

.DataAgreement_DataAgreement__1i7_e > h2 {
  font-size: 3.35vw;
  color: black;
  font-weight: bold;
  line-height: 4.37vw;
  padding: 0;
  margin: 0 0 1.75vw 0; }

.DataAgreement_DataAgreement__1i7_e > p {
  margin: 0;
  font-size: 1.31vw;
  line-height: 2.04vw; }

.DataAgreement_DataAgreement__1i7_e > div {
  width: 13.43vw;
  margin: 2.62vw auto 0; }

@media (max-width: 900px) {
  .DataAgreement_DataAgreement__1i7_e {
    padding: 9.96vw 10.21vw 9.96vw; }
  .DataAgreement_DataAgreement__1i7_e > h2 {
    font-size: 6.04vw;
    line-height: 7.5vw;
    padding: 0 1vw;
    margin: 0 0 6.12vw 0; }
  .DataAgreement_DataAgreement__1i7_e > p {
    font-size: 2.47vw;
    line-height: 4.39vw; }
  .DataAgreement_DataAgreement__1i7_e > div {
    width: 40vw;
    margin: 7.62vw auto 0; } }

@media (max-width: 460px) {
  .DataAgreement_DataAgreement__1i7_e {
    padding: 9.96vw 10.21vw 9.96vw; }
  .DataAgreement_DataAgreement__1i7_e > h2 {
    font-size: 6.04vw;
    line-height: 7.5vw;
    padding: 0 1vw;
    margin: 0 0 6.12vw 0; }
  .DataAgreement_DataAgreement__1i7_e > p {
    font-size: 3.91vw;
    line-height: 5.04vw; }
  .DataAgreement_DataAgreement__1i7_e > div {
    width: 40vw;
    margin: 7.62vw auto 0; } }

.SendModalForm_SendModalForm__2m3P_ {
  position: relative; }

.SendModalForm_SendModalForm__2m3P_ > h4 {
  color: black;
  text-align: center;
  font-size: 1.6vw;
  margin-bottom: 2.33vw;
  padding: 3.79vw 1vw 0 1vw; }

.SendModalForm_closeButton__3UUrf {
  cursor: pointer;
  position: absolute;
  width: 2.04vw;
  right: -1.02vw;
  top: -1.02vw;
  height: 2.04vw;
  border-radius: 0.72vw;
  background-color: #005ba8; }

.SendModalForm_closeButton__3UUrf:hover {
  opacity: 1; }

.SendModalForm_closeButton__3UUrf > div {
  position: absolute;
  width: 0.14vw;
  height: 1.31vw;
  left: 0.97vw;
  top: 0.4vw;
  margin: 0;
  background-color: white; }

.SendModalForm_closeButton__3UUrf > div:first-child {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.SendModalForm_closeButton__3UUrf > div:last-child {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.SendModalForm_customForm__1awfI {
  flex-direction: column;
  padding: 0 9.92vw 3.79vw 9.92vw; }

.SendModalForm_customForm__1awfI .ant-form-item-control-wrapper {
  width: 100%; }

.SendModalForm_customForm__1awfI input {
  text-align: center; }

.SendModalForm_customForm__1awfI > * {
  width: 100% !important;
  margin-right: 0 !important; }

.SendModalForm_customForm__1awfI > div:first-child {
  margin-bottom: 1.16vw; }

.SendModalForm_customForm__1awfI > div:last-child > p {
  text-align: center;
  color: #010101;
  opacity: 0.4; }

.SendModalForm_customForm__1awfI > div:last-child > p > span {
  text-decoration: none;
  border-bottom: 1px dashed #010101;
  cursor: pointer; }

@media (max-width: 900px) {
  .SendModalForm_SendModalForm__2m3P_ > h4 {
    font-size: 2.47vw;
    margin-bottom: 4.39vw;
    padding: 6.04vw 1vw 0 1vw; }
  .SendModalForm_closeButton__3UUrf {
    width: 3.84vw;
    right: -1.92vw;
    top: -1.92vw;
    height: 3.84vw;
    border-radius: 1.37vw; }
  .SendModalForm_closeButton__3UUrf > div {
    width: 0.27vw;
    height: 2.47vw;
    left: 1.7vw;
    top: 0.7vw; }
  .SendModalForm_customForm__1awfI {
    padding: 0 12vw 6.04vw 12vw;
    width: 100%; }
  .SendModalForm_customForm__1awfI > div:first-child {
    margin-bottom: 2.2vw; }
  .SendModalForm_customForm__1awfI > div:last-child > p {
    text-align: left;
    margin-top: 1.64vw;
    font-size: 1.64vw; } }

@media (max-width: 460px) {
  .SendModalForm_SendModalForm__2m3P_ > h4 {
    font-size: 3.91vw;
    margin-bottom: 6.95vw;
    padding: 9.56vw 1vw 0 1vw; }
  .SendModalForm_SupportBlock__2EOnW > article > p {
    font-size: 3.91vw;
    line-height: 4.78vw; }
  .SendModalForm_closeButton__3UUrf {
    width: 6.08vw;
    height: 6.08vw;
    right: -3.04vw;
    top: -3.04vw;
    border-radius: 2.17vw; }
  .SendModalForm_closeButton__3UUrf > div {
    width: 0.43vw;
    height: 3.91vw;
    left: 2.8vw;
    top: 1.1vw; }
  .SendModalForm_customForm__1awfI {
    padding: 0 16vw 9.56vw 16vw; }
  .SendModalForm_customForm__1awfI > div:first-child {
    margin-bottom: 3.47vw; }
  .SendModalForm_customForm__1awfI > div:last-child > p {
    margin-top: 2.6vw;
    font-size: 2.6vw; }
  .SendModalForm_customForm__1awfI > div:last-child > p > span {
    border-bottom: 1px dashed #010101; }
  .SendModalForm_customForm__1awfI > div:last-child > p > span:hover {
    border-bottom: 1px dashed #005ba8; } }

.Loans_Loans__1m3tA {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 32.7vw;
  margin: 8.02vw 0 0 0 !important;
  padding: 2.91vw 9.48vw 2.18vw;
  background-image: linear-gradient(#206eb2 50%, rgba(16, 178, 170, 0.7));
  overflow: hidden; }

.Loans_Loans__1m3tA > picture {
  position: absolute;
  height: 22.62vw;
  right: -5.5vw;
  bottom: 4vw;
  z-index: 10; }

.Loans_Loans__1m3tA > picture > * {
  height: 100%;
  width: auto; }

.Loans_Loans__1m3tA > picture:first-child {
  display: none; }

.Loans_Loans__1m3tA > picture.Loans_action__38RZJ {
  height: 17.4vw;
  bottom: 14vw;
  right: 16vw; }

.Loans_lighter__1Lm62 {
  height: 43.79vw;
  width: 58.39vw;
  position: absolute;
  bottom: -7vw;
  right: -16vw;
  background-image: radial-gradient(ellipse farthest-corner at 50% 50%, #f9f9f9 -118px, rgba(80, 80, 80, 0) 56%);
  border-radius: 50%;
  z-index: 9; }

.Loans_Loans__1m3tA > h1 {
  z-index: 200;
  font-size: 5vw;
  line-height: 4.7vw;
  margin-bottom: 2.91vw;
  color: white; }

.Loans_Loans__1m3tA > ul {
  color: white;
  font-size: 2.04vw;
  margin-bottom: 2.91vw; }

.Loans_Loans__1m3tA > ul > li {
  display: flex;
  align-items: center; }

.Loans_Loans__1m3tA > ul > li > div {
  display: inline-block;
  background-color: white;
  width: 1.16vw;
  height: 0.29vw;
  margin-right: 1.89vw; }

@media (max-width: 900px) {
  .Loans_Loans__1m3tA {
    margin-top: 15.1vw !important;
    min-height: 82.41vw;
    padding: 6.86vw 4.12vw;
    background-image: linear-gradient(#206eb2 50%, rgba(16, 178, 170, 0.7));
    overflow: hidden; }
  .Loans_Loans__1m3tA > picture {
    width: auto;
    height: 26.82vw;
    right: -22vw;
    bottom: 12vw; }
  .Loans_Loans__1m3tA > picture.Loans_action__38RZJ {
    height: 25.4vw;
    bottom: 56vw;
    right: 4vw; }
  .Loans_lighter__1Lm62 {
    position: absolute;
    bottom: 5vw;
    right: -28vw;
    background-image: radial-gradient(ellipse farthest-corner at 50% 50%, #f9f9f9 -118px, rgba(80, 80, 80, 0) 56%);
    border-radius: 50%;
    z-index: 9; }
  .Loans_Loans__1m3tA > h1 {
    width: 80%;
    font-size: 9.89vw;
    line-height: 11vw;
    margin-bottom: 4.52vw;
    color: white; }
  .Loans_Loans__1m3tA > ul {
    font-size: 3.84vw;
    margin-bottom: 4.24vw; }
  .Loans_Loans__1m3tA > ul > li > div {
    width: 2.2vw;
    height: 0.4vw;
    margin-right: 1.89vw; } }

@media (max-width: 460px) {
  .Loans_Loans__1m3tA {
    margin-top: 12.17vw !important;
    min-height: 130.43vw;
    padding: 7vw 4.12vw 50vw; }
  .Loans_Loans__1m3tA > picture:nth-child(2) {
    display: none; }
  .Loans_Loans__1m3tA > picture:first-child {
    display: initial;
    width: 100vw;
    height: auto;
    top: auto;
    right: 0;
    bottom: -20vw; }
  .Loans_Loans__1m3tA > picture:first-child > img {
    height: auto;
    width: 100%; }
  .Loans_Loans__1m3tA > picture.Loans_action__38RZJ {
    height: 38.4vw;
    bottom: 9vw;
    right: 50vw; }
  .Loans_lighter__1Lm62 {
    bottom: -12vw;
    right: -28vw;
    height: 70vw;
    width: 121vw; }
  .Loans_Loans__1m3tA > h1 {
    width: 80%;
    font-size: 11vw;
    line-height: 11vw;
    margin-bottom: 6.95vw; }
  .Loans_Loans__1m3tA > ul {
    font-size: 3.91vw;
    margin-bottom: 6.52vw; }
  .Loans_Loans__1m3tA > ul > li > div {
    width: 3.47vw;
    height: 0.65vw;
    margin-right: 1.89vw; } }

.Notificator_notificator__2kNNI {
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #005ba8;
  min-height: 3.15vw;
  margin: 0 !important;
  padding: 0 9.48vw;
  transition: 0.2s; }

.Notificator_notificator__2kNNI > div,
.Notificator_notificator__2kNNI > div > * > a {
  color: white;
  font-size: 0.84vw;
  font-weight: bold; }

.Notificator_notificator__2kNNI > div > * > a {
  border-bottom: 0.1vw solid var(--color2); }

.Notificator_notificator__2kNNI > div > * > a:hover {
  color: white; }

.Notificator_notificator__2kNNI > img {
  cursor: pointer;
  width: 1.47vw;
  height: 1.47vw;
  transition: 0.1s; }

.Notificator_notificator__2kNNI > img:hover {
  opacity: 0.9; }

.Notificator_notificator__2kNNI {
  min-height: 4.34vw; }

.Notificator_notificator__2kNNI > div,
.Notificator_notificator__2kNNI > div > * > a {
  font-size: 1.15vw; }

.Notificator_notificator__2kNNI > div > * > a {
  border-bottom: 0.15vw solid var(--color2); }

.Notificator_notificator__2kNNI > img {
  width: 2.02vw;
  height: 2.02vw; }

@media (max-width: 900px) {
  .Notificator_notificator__2kNNI {
    min-height: 7.5vw;
    padding: 0 4.12vw; }
  .Notificator_notificator__2kNNI > div,
  .Notificator_notificator__2kNNI > div > * > a {
    font-size: 2vw; }
  .Notificator_notificator__2kNNI > div > * > a {
    border-bottom: 1px solid var(--color2); }
  .Notificator_notificator__2kNNI > img {
    width: 3.5vw;
    height: 3.5vw; } }

@media (max-width: 460px) {
  .Notificator_notificator__2kNNI {
    min-height: 13.04vw; }
  .Notificator_notificator__2kNNI > div {
    width: 100%; }
  .Notificator_notificator__2kNNI > div,
  .Notificator_notificator__2kNNI > div > * > a {
    font-size: 3.47vw; }
  .Notificator_notificator__2kNNI > div > * > a {
    border-bottom: 0.2vw solid var(--color2); }
  .Notificator_notificator__2kNNI > img {
    width: 6.08vw;
    height: 6.08vw; } }

.App_App__3iQRb {
  display: flex;
  flex-direction: column; }

.App_App__3iQRb > * {
  margin: 0 9.48vw; }

.App_Loans__2_hlO {
  margin: 0 !important; }

.App_anchor__342KB {
  position: relative;
  top: -6vw; }

.App_Calc__3igiE section section {
  margin: 0 !important; }

.App_Calc__3igiE h2 {
  margin-bottom: 0px !important; }

.App_App__3iQRb .ant-back-top {
  z-index: 100;
  margin: 0 !important;
  right: 13vw;
  bottom: 5vw; }

.App_mover__qiue3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.05vw;
  height: 4.05vw;
  border-radius: 0.72vw;
  background-color: #005ba8;
  color: white; }

.App_mover__qiue3 > i {
  font-size: 2.6vw;
  line-height: 2.6vw; }

@media (max-width: 900px) {
  .App_App__3iQRb > * {
    margin: 0 4.12vw; }
  .App_App__3iQRb .ant-back-top {
    margin: 0 !important;
    right: 10.18vw;
    bottom: 8vw; }
  .App_mover__qiue3 {
    width: 7vw;
    height: 7vw;
    border-radius: 1.25vw; }
  .App_mover__qiue3 > i {
    color: white;
    font-size: 4vw;
    line-height: 4vw; } }

@media (max-width: 460px) {
  .App_App__3iQRb > * {
    margin: 0 4.12vw; }
  .App_App__3iQRb .ant-back-top {
    margin: 0 !important;
    right: 12.18vw;
    bottom: 10vw; }
  .App_mover__qiue3 {
    width: 11vw;
    height: 11vw;
    border-radius: 2.17vw; }
  .App_mover__qiue3 > i {
    font-size: 6.95vw;
    line-height: 6.95vw; } }

.App_messengers__3cauq {
  position: fixed;
  bottom: 5vw !important;
  right: 2vw !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 1.5vw;
  z-index: 100;
  margin: 0 !important; }

.App_messengers__3cauq > a {
  width: 4.05vw;
  height: 4.05vw;
  border-radius: 0.72vw;
  cursor: pointer;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; }

.App_messengers__3cauq > a img {
  width: 100%; }

@media (max-width: 900px) {
  .App_messengers__3cauq {
    bottom: 5vw !important; }
  .App_messengers__3cauq > a {
    width: 7vw;
    height: 7vw;
    border-radius: 1.25vw; } }

@media (max-width: 460px) {
  .App_messengers__3cauq {
    bottom: 5vw !important; }
  .App_messengers__3cauq > a {
    width: 11vw;
    height: 11vw;
    border-radius: 2.17vw; } }

.Stats_clear__3smZr {
  clear: both; }

.Stats_countdown__uDcT_ * {
  margin-top: 0px !important; }

.Stats_countdown__uDcT_ {
  margin-top: 1vw;
  margin-bottom: 1vw; }

.Stats_countdownTitle__rJkfe {
  font-size: 1.9vw;
  margin: 0; }

.Stats_digits__2TU9J {
  text-align: center;
  width: 7.1vw !important;
  float: left; }

.Stats_digits__2TU9J::before {
  content: ":";
  font-size: 2.4vw;
  line-height: 4vw; }

.Stats_digits__2TU9J:last-of-type::before {
  content: ""; }

.Stats_digits__2TU9J .Stats_digit__t_7DO {
  float: left;
  background-color: #ff6f00;
  color: white;
  font-weight: bold;
  border-radius: 0.6vw;
  padding: 0.4vw 0.8vw;
  display: inline-block;
  margin-left: 0.2vw;
  margin-top: 0px !important;
  font-size: 2.4vw; }

.Stats_advertBlock__3G-Y1 {
  margin-top: 50px;
  border: solid 1px #fff;
  background: linear-gradient(262.75deg, #a0cb83 5.87%, #06ae35 96.56%);
  box-shadow: 0 4px 4px rgba(164, 48, 255, 0.25);
  color: #fff;
  cursor: pointer; }

.Stats_advertBlock__3G-Y1 h2 {
  color: #fff !important; }

.Stats_advertBlock__3G-Y1 a, .Stats_advertBlock__3G-Y1 a:visited, .Stats_advertBlock__3G-Y1 a:focus, .Stats_advertBlock__3G-Y1 a:hover {
  box-shadow: 0 10px 28px 0 rgba(255, 168, 0, 0.6);
  background-color: #ff6f00;
  border: 1px solid #fff;
  height: 5vw;
  font-size: 1.4vw;
  line-height: 5vw; }

.Stats_advertBlock__3G-Y1 div {
  margin-top: 35px; }

.Stats_advertBlock__3G-Y1 div p {
  margin-top: 20px;
  line-height: 1.5vw; }

.Stats_Stats__3_kvk {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4.96vw;
  margin-bottom: 0; }

.Stats_Stats__3_kvk > article:first-child {
  width: 69%;
  padding-right: 6.56vw;
  margin-right: 0px; }

.Stats_Stats__3_kvk > article:nth-child(2) {
  width: 31%;
  margin: 0px !important; }

.Stats_Stats__3_kvk > article:nth-child(3) {
  margin-right: 0; }

.Stats_links__11uW6 {
  display: none;
  width: 100%; }

.Stats_links__11uW6 > div {
  cursor: pointer;
  border-bottom: 0.1vw solid #767676; }

.Stats_periodLink__2l8CW {
  transition: 0.1s;
  cursor: pointer; }

.Stats_periodLink__2l8CW:hover,
.Stats_periodLink__2l8CW:hover > span {
  color: #005ba8; }

.Stats_periodLink__2l8CW > span {
  color: red; }

.Stats_link__3cqgC {
  color: black;
  border-bottom: 0 !important; }

.Stats_info__3ICIi {
  padding: 4.96vw 10.21vw 2.91vw; }

.Stats_info__3ICIi {
  text-align: center; }

.Stats_info__3ICIi > p {
  margin: 0;
  font-size: 1.31vw;
  line-height: 2.04vw; }

.Stats_info__3ICIi > div {
  width: 13.43vw;
  margin: 2.62vw auto 0; }

.Stats_noblick_btn__1TfdM {
  overflow-x: visible !important; }

.Stats_noblick_btn__1TfdM::after {
  display: none; }

@media (max-width: 900px) {
  .Stats_Stats__3_kvk > article {
    width: 100% !important;
    padding-right: 0px !important; }
  .Stats_digits__2TU9J {
    width: 13.1vw !important; }
  .Stats_digits__2TU9J::before {
    font-size: 5.4vw;
    line-height: 7vw; }
  .Stats_digits__2TU9J .Stats_digit__t_7DO {
    border-radius: 0.8vw;
    padding: 0.9vw 1.8vw;
    font-size: 3.4vw; }
  .Stats_countdownTitle__rJkfe {
    font-size: 3.9vw; }
  .Stats_calcButton__3BIvR {
    font-size: 2vw; }
  .Stats_advertBlock__3G-Y1 {
    min-height: auto !important; }
  .Stats_advertBlock__3G-Y1 h2 {
    color: #fff !important; }
  .Stats_advertBlock__3G-Y1 a, .Stats_advertBlock__3G-Y1 a:visited, .Stats_advertBlock__3G-Y1 a:focus, .Stats_advertBlock__3G-Y1 a:hover {
    height: 12vw;
    font-size: 4vw;
    line-height: 12vw; }
  .Stats_advertBlock__3G-Y1 .Stats_advertBlockButton__3hW5J {
    width: 100%; }
  .Stats_advertBlock__3G-Y1 div p {
    line-height: 2.5vw; }
  .Stats_Stats__3_kvk {
    margin-top: 9.89vw;
    margin-bottom: 4.4vw; }
  .Stats_links__11uW6 {
    display: flex;
    color: #767676;
    margin-top: 5.49vw;
    font-size: 2.47vw; }
  .Stats_links__11uW6 > div {
    line-height: 3.84vw; }
  .Stats_links__11uW6 > div:first-child {
    margin-right: 9.61vw; }
  .Stats_info__3ICIi {
    padding: 9.96vw 10.21vw 9.96vw; }
  .Stats_info__3ICIi > p {
    font-size: 2.47vw;
    line-height: 4.39vw; }
  .Stats_info__3ICIi > div {
    width: 40vw;
    margin: 7.62vw auto 0; } }

@media (max-width: 460px) {
  .Stats_Stats__3_kvk > article {
    width: 100% !important;
    padding-right: 0px !important; }
  .Stats_calcButton__3BIvR {
    font-size: 3.47vw; }
  .Stats_advertBlock__3G-Y1 {
    min-height: auto !important; }
  .Stats_advertBlock__3G-Y1 h2 {
    font-size: 6.8vw !important;
    width: 100% !important; }
  .Stats_advertBlock__3G-Y1 a, .Stats_advertBlock__3G-Y1 a:visited, .Stats_advertBlock__3G-Y1 a:focus, .Stats_advertBlock__3G-Y1 a:hover {
    height: 20vw;
    font-size: 5vw;
    line-height: 20vw; }
  .Stats_advertBlock__3G-Y1 .Stats_advertBlockButton__3hW5J {
    width: 100%; }
  .Stats_advertBlock__3G-Y1 div p {
    font-size: 3.3vw;
    line-height: 4vw; }
  .Stats_Stats__3_kvk {
    margin-top: 10.86vw;
    margin-bottom: 6.52vw; }
  .Stats_Stats__3_kvk .ant-slider {
    margin: 6.52vw 0 9.56vw; }
  .Stats_links__11uW6 {
    display: flex;
    color: #767676;
    margin-top: 8.69vw;
    font-size: 3.91vw;
    line-height: 3.02vw; }
  .Stats_links__11uW6 > div {
    line-height: 5.65vw; }
  .Stats_links__11uW6 > div:first-child {
    margin-right: 6.52vw; }
  .Stats_info__3ICIi {
    padding: 9.96vw 10.21vw 9.96vw; }
  .Stats_info__3ICIi > p {
    font-size: 3.91vw;
    line-height: 5.04vw; }
  .Stats_info__3ICIi > div {
    width: 40vw;
    margin: 7.62vw auto 0; }
  .Stats_countdown__uDcT_ .Stats_countdownTitle__rJkfe {
    font-size: 4vw;
    margin-bottom: 0.1vw; }
  .Stats_digits__2TU9J {
    width: 14vw !important; }
  .Stats_digit__t_7DO {
    padding: 1vw 1.6vw;
    margin-left: 0.4vw; }
  .Stats_digitCaption__1bZG7 {
    font-size: 3vw; } }

.SupportBlock_anchor__2PK12 {
  position: relative;
  top: -14vw; }

.SupportBlock_SupportBlock__2cQmV {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f1f1f1;
  border-radius: 0.72vw;
  width: 100%;
  min-height: 22.62vw;
  padding: 5.1vw 5.83vw; }

.SupportBlock_SupportBlock__2cQmV > article {
  width: 38.5vw; }

.SupportBlock_SupportBlock__2cQmV > article > h2 {
  font-size: 3.35vw;
  line-height: 3.64vw;
  color: black;
  font-weight: bold;
  margin-bottom: 2.33vw; }

.SupportBlock_SupportBlock__2cQmV > article > p {
  font-size: 2.04vw;
  line-height: 2.62vw;
  margin: 0; }

.SupportBlock_customForm__nJtkC {
  flex-direction: column; }

.SupportBlock_customForm__nJtkC input {
  text-align: center; }

.SupportBlock_customForm__nJtkC > * {
  width: 100% !important;
  margin-right: 0 !important; }

.SupportBlock_customForm__nJtkC > div:first-child {
  margin-bottom: 1.16vw; }

.SupportBlock_customForm__nJtkC > div:last-child > p {
  text-align: center;
  color: #010101;
  opacity: 0.4; }

.SupportBlock_customForm__nJtkC > div:last-child > p > span {
  text-decoration: none;
  border-bottom: 1px dashed #010101;
  cursor: pointer;
  transition: 0.1s; }

@media (max-width: 900px) {
  .SupportBlock_SupportBlock__2cQmV {
    flex-direction: column;
    border-radius: 1.37vw;
    width: 100%;
    min-height: 64.28vw;
    padding: 7.41vw 7.41vw; }
  .SupportBlock_SupportBlock__2cQmV > article {
    width: 100%; }
  .SupportBlock_SupportBlock__2cQmV > article > h2 {
    font-size: 6.18vw;
    line-height: 7vw;
    margin-bottom: 4.34vw; }
  .SupportBlock_SupportBlock__2cQmV > article > p {
    font-size: 3.84vw;
    line-height: 4.94vw;
    margin: 0; }
  .SupportBlock_customForm__nJtkC {
    width: 100%; }
  .SupportBlock_customForm__nJtkC > div:first-child {
    margin-bottom: 2.2vw; }
  .SupportBlock_customForm__nJtkC > div:last-child > p {
    text-align: left;
    margin-top: 1.64vw;
    font-size: 1.64vw; } }

@media (max-width: 460px) {
  .SupportBlock_SupportBlock__2cQmV {
    border-radius: 2.17vw;
    min-height: 102vw;
    padding: 9.56vw 7.39vw; }
  .SupportBlock_SupportBlock__2cQmV > article > h2 {
    font-size: 7.82vw;
    line-height: 10.69vw;
    width: 52vw;
    margin-bottom: 6.95vw; }
  .SupportBlock_SupportBlock__2cQmV > article > p {
    font-size: 3.91vw;
    line-height: 4.78vw; }
  .SupportBlock_customForm__nJtkC > div:first-child {
    margin-bottom: 3.47vw; }
  .SupportBlock_customForm__nJtkC > div:last-child > p {
    margin-top: 2.6vw;
    font-size: 2.6vw; }
  .SupportBlock_customForm__nJtkC > div:last-child > p > span {
    border-bottom: 1px dashed #010101; }
  .SupportBlock_customForm__nJtkC > div:last-child > p > span:hover {
    border-bottom: 1px dashed #005ba8; } }

.CreditTermItem_ServiceBy__2EnTe {
  font-style: italic;
  padding-top: 3vw;
  font-size: 0.9vw; }

.CreditTermItem_ServiceByTablet__126Kl {
  font-style: italic;
  padding-top: 3vw;
  font-size: 0.9vw;
  display: none; }

.CreditTermItem_CreditTermItem__1NzX- {
  display: flex;
  flex-direction: column;
  width: 20.14vw;
  margin-right: 6.56vw; }

.CreditTermItem_CreditTermItem__1NzX- .calcExample {
  font-size: 0.8vw;
  line-height: 0.9vw;
  color: #767676; }

.CreditTermItem_CreditTermItem__1NzX- button {
  margin-bottom: 2vw;
  margin-top: 2vw; }

.CreditTermItem_CreditTermItem__1NzX- .ant-input-number {
  border: 0;
  height: auto; }

.CreditTermItem_CreditTermItem__1NzX- .ant-input-number-focused {
  box-shadow: initial; }

.CreditTermItem_CreditTermItem__1NzX- .ant-input-number-input,
.CreditTermItem_CreditTermItem__1NzX- .ant-form {
  font-size: 3.28vw;
  white-space: nowrap;
  height: auto;
  padding: 0; }

.CreditTermItem_CreditTermItem__1NzX- .ant-slider-mark-text {
  -webkit-transform: translateX(-100%) !important;
          transform: translateX(-100%) !important;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.45);
  padding-top: 1vw; }

.CreditTermItem_CreditTermItem__1NzX- .ant-slider-mark-text:first-child {
  -webkit-transform: translateX(0%) !important;
          transform: translateX(0%) !important; }

.CreditTermItem_CreditTermItem__1NzX- > h4 {
  color: black;
  margin-top: 1vw;
  font-size: 1.31vw;
  line-height: 2.60vw; }

.CreditTermItem_CreditTermItem__1NzX- > div {
  width: 100%; }

.CreditTermItem_slider__xBlnO {
  width: 100%; }

.CreditTermItem_CreditTermItem__1NzX- > div > span {
  display: inline-block;
  color: black;
  margin-top: 0.72vw;
  font-size: 1.31vw;
  line-height: 1.6vw;
  transition: 0s; }

.CreditTermItem_linkLabel__2eRtY {
  cursor: pointer;
  color: #767676 !important;
  border-bottom: 0.1vw solid #767676;
  transition: 0.2s; }

.CreditTermItem_linkLabel__2eRtY:hover {
  color: black !important;
  border-bottom: 0.1vw solid black; }

.CreditTermItem_finishCredit__wOmmQ {
  margin-top: 2vw !important; }

.CreditTermItem_finishCredit2__3D8M6 {
  display: none !important; }

@media (max-width: 900px) {
  .CreditTermItem_finishCredit__wOmmQ {
    display: none !important; }
  .CreditTermItem_finishCredit2__3D8M6 {
    display: block !important;
    color: #000 !important;
    margin-bottom: 2vw !important;
    margin-top: 2vw !important; }
  .CreditTermItem_CreditTermItem__1NzX- .calcExample {
    font-size: 2.0vw;
    line-height: 2.3vw; }
  .CreditTermItem_CreditTermItem__1NzX- {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-right: 0; }
  .CreditTermItem_CreditTermItem__1NzX- > h4 {
    margin: 0;
    margin-top: 3vw;
    min-width: 37.91vw;
    font-size: 2.84vw;
    line-height: 4.94vw; }
  .CreditTermItem_CreditTermItem__1NzX- > div {
    width: 100%; }
  .CreditTermItem_CreditTermItem__1NzX- .ant-input-number-input,
  .CreditTermItem_CreditTermItem__1NzX- .ant-form {
    font-size: 6.18vw; }
  .CreditTermItem_slider__xBlnO {
    width: 100%; }
  .CreditTermItem_CreditTermItem__1NzX- > div > span {
    color: #767676;
    margin-top: 1.37vw;
    font-size: 2.47vw;
    line-height: 3.02vw; }
  .CreditTermItem_linkLabel__2eRtY,
  .CreditTermItem_inactiveLink__17Ueh,
  .CreditTermItem_CreditTermItem__1NzX- .disabledLink {
    display: none !important; }
  .CreditTermItem_ServiceByTablet__126Kl {
    display: block !important; }
  .CreditTermItem_ServiceBy__2EnTe {
    display: none; } }

@media (max-width: 460px) {
  .CreditTermItem_finishCredit__wOmmQ {
    display: none !important; }
  .CreditTermItem_finishCredit2__3D8M6 {
    display: block !important;
    color: #000 !important;
    margin-bottom: 2vw !important;
    margin-top: 2vw !important; }
  .CreditTermItem_CreditTermItem__1NzX- .calcExample {
    font-size: 3vw;
    line-height: 3vw;
    color: #767676; }
  .CreditTermItem_CreditTermItem__1NzX- {
    display: flex;
    flex-direction: column;
    align-items: end; }
  .CreditTermItem_CreditTermItem__1NzX- > h4 {
    margin: 0;
    font-size: 3.91vw;
    line-height: 6.08vw; }
  .CreditTermItem_CreditTermItem__1NzX- .ant-input-number-input,
  .CreditTermItem_CreditTermItem__1NzX- .ant-form {
    font-size: 7.82vw; }
  .CreditTermItem_CreditTermItem__1NzX- > div > span {
    margin-top: 2.17vw;
    font-size: 3.91vw;
    line-height: 4.78vw; }
  .CreditTermItem_slider__xBlnO {
    width: 96%;
    margin: 0 auto; }
  .CreditTermItem_ServiceBy__2EnTe {
    font-size: 3vw;
    display: block !important; }
  .CreditTermItem_ServiceByTablet__126Kl {
    display: none !important; } }

.Steps_Steps__10KC3 {
  display: flex;
  justify-content: space-between; }

.Steps_Steps__10KC3 > ul {
  width: 44vw; }

.Steps_Steps__10KC3 > ul > li {
  display: flex;
  align-items: flex-start; }

.Steps_Steps__10KC3 > ul > li > img {
  width: 3.94vw;
  min-width: 3.94vw;
  margin-right: 2.18vw; }

.Steps_Steps__10KC3 > ul > li {
  margin-bottom: 2.48vw; }

.Steps_Steps__10KC3 > ul > li:last-child {
  margin: 0; }

.Steps_text__Cs8cg > header {
  display: inline-block;
  margin-bottom: 0.87vw;
  font-size: 2.04vw;
  line-height: 2.77vw;
  font-weight: bold; }

.Steps_textLink__2hwAd {
  cursor: pointer;
  transition: 0.1s; }

.Steps_textLink__2hwAd:hover {
  color: #005ba8; }

.Steps_text__Cs8cg > p, .Steps_Steps__10KC3 > div > p {
  margin: 0;
  font-size: 1.31vw; }

.Steps_Steps__10KC3 > div > p {
  text-align: center;
  width: 100%; }

.Steps_text__Cs8cg > p > a {
  cursor: pointer;
  color: #005ba8;
  border-bottom: 0.1vw solid #005ba8; }

.Steps_Steps__10KC3 > div {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30vw;
  height: 10.36vw;
  padding: 1.02vw 1.37vw 1.75vw 1.89vw;
  border-bottom: 0.24vw solid #dcdcdc;
  border-left: 0.24vw solid #dcdcdc;
  border-bottom-left-radius: 0.72vw; }

.Steps_Steps__10KC3 > div > img:first-child {
  position: absolute;
  width: 8.02vw;
  height: 11vw;
  left: -1vw;
  top: 13vw; }

.Steps_Steps__10KC3 > div > picture:nth-child(2) {
  width: 6.93vw;
  min-width: 6.93vw;
  height: 6.86vw;
  margin-right: 1.75vw; }

.Steps_Steps__10KC3 > div > picture:nth-child(2) > img {
  width: 100%; }

.Steps_modalMenu__2LGpK > div {
  width: 40.43vw !important; }

@media (max-width: 900px) {
  .Steps_Steps__10KC3 {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse; }
  .Steps_Steps__10KC3 > ul {
    width: 100%; }
  .Steps_Steps__10KC3 > ul > li > img {
    width: 7.41vw;
    min-width: 7.41vw;
    margin-right: 4.12vw; }
  .Steps_Steps__10KC3 > ul > li {
    margin-bottom: 4.67vw; }
  .Steps_Steps__10KC3 > ul > li:last-child {
    margin: 0; }
  .Steps_text__Cs8cg > header {
    margin-bottom: 1.64vw;
    font-size: 3.84vw;
    line-height: 4.94vw; }
  .Steps_text__Cs8cg > p, .Steps_Steps__10KC3 > div > p {
    font-size: 2.47vw; }
  .Steps_text__Cs8cg > p > a {
    border-bottom: 0.2vw solid #005ba8; }
  .Steps_Steps__10KC3 > div {
    width: 100%;
    margin-bottom: 6.86vw;
    justify-content: flex-start;
    height: 19.5vw;
    padding: 1.02vw 7.69vw;
    border: 0.43vw solid #dcdcdc;
    border-radius: 1.37vw; }
  .Steps_Steps__10KC3 > div > p {
    width: 34vw; }
  .Steps_Steps__10KC3 > div > img:first-child {
    display: none; }
  .Steps_Steps__10KC3 > div > picture:nth-child(2) {
    width: 13.04vw;
    min-width: 13.04vw;
    height: 12.91vw;
    margin-right: 4.39vw; }
  .Steps_modalMenu__2LGpK > div {
    width: 64vw !important; } }

@media (max-width: 460px) {
  .Steps_Steps__10KC3 > ul > li > img {
    width: 11.73vw;
    min-width: 11.73vw;
    margin-right: 6.52vw; }
  .Steps_Steps__10KC3 > ul > li {
    margin-bottom: 7.39vw; }
  .Steps_Steps__10KC3 > ul > li:last-child {
    margin: 0; }
  .Steps_text__Cs8cg > header {
    margin-bottom: 2.6vw;
    font-size: 6.08vw;
    line-height: 8.69vw; }
  .Steps_text__Cs8cg > p, .Steps_Steps__10KC3 > div > p {
    font-size: 3.91vw; }
  .Steps_text__Cs8cg > p > a {
    border-bottom: 0.4vw solid #005ba8; }
  .Steps_Steps__10KC3 > div {
    margin-bottom: 10.86vw;
    height: 31.73vw;
    padding: 1.02vw 3.95vw;
    border: 0.8vw solid #dcdcdc;
    border-radius: 2.17vw; }
  .Steps_Steps__10KC3 > div > p {
    width: 100%; }
  .Steps_Steps__10KC3 > div > picture:nth-child(2) {
    width: 20.65vw;
    min-width: 20.65vw;
    height: 20.43vw;
    margin-right: 6.95vw; }
  .Steps_modalMenu__2LGpK > div {
    width: 90vw !important; } }

.CreditInfo_leftContent__1xqVC > ul {
  color: black;
  font-size: 2.04vw;
  margin-bottom: 5.1vw;
  padding-left: 3.5vw; }

.CreditInfo_leftContent__1xqVC > ul > li {
  display: flex;
  align-items: center;
  margin-bottom: 0.43vw; }

.CreditInfo_leftContent__1xqVC > ul > li > div {
  display: inline-block;
  background-color: #dcdcdc;
  width: 1.16vw;
  height: 0.29vw;
  margin-right: 1.89vw; }

.CreditInfo_CreditInfo__2zSj5 > .CreditInfo_checkBlock__bSGnu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7vw; }

.CreditInfo_CreditInfo__2zSj5 > .CreditInfo_checkBlock__bSGnu > article {
  width: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 2.04vw;
  line-height: 2.62vw; }

.CreditInfo_CreditInfo__2zSj5 > .CreditInfo_checkBlock__bSGnu > article > img {
  width: 2.91vw;
  height: 2.48vw;
  margin-bottom: 1.75vw; }

@media (max-width: 900px) {
  .CreditInfo_leftContent__1xqVC > ul {
    font-size: 3.84vw;
    margin-bottom: 6.04vw;
    padding-left: 0; }
  .CreditInfo_leftContent__1xqVC > ul > li {
    margin-bottom: 0.82vw; }
  .CreditInfo_leftContent__1xqVC > ul > li > div {
    width: 2.2vw;
    height: 0.54vw;
    margin-right: 3.57vw; }
  .CreditInfo_CreditInfo__2zSj5 > .CreditInfo_checkBlock__bSGnu {
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap; }
  .CreditInfo_CreditInfo__2zSj5 > .CreditInfo_checkBlock__bSGnu > article {
    width: 38vw;
    font-size: 3.84vw;
    line-height: 4.94vw;
    margin-bottom: 8.24vw; }
  .CreditInfo_CreditInfo__2zSj5 > .CreditInfo_checkBlock__bSGnu > article > img {
    width: 7.14vw;
    height: 7.14vw;
    margin-bottom: 3.3vw; } }

@media (max-width: 460px) {
  .CreditInfo_leftContent__1xqVC > ul {
    font-size: 3.91vw;
    margin-bottom: 0;
    padding-bottom: 9.56vw;
    padding-left: 0;
    padding-right: 4vw;
    border-bottom: 0.86vw solid #e8e8e8; }
  .CreditInfo_leftContent__1xqVC > ul > li {
    margin-bottom: 1.73vw; }
  .CreditInfo_leftContent__1xqVC > ul > li > div {
    width: 3.47vw;
    min-width: 3.47vw;
    height: 0.86vw;
    margin-right: 6.08vw; }
  .CreditInfo_CreditInfo__2zSj5 > .CreditInfo_checkBlock__bSGnu {
    margin-top: 9.56vw;
    flex-direction: column;
    align-items: start;
    flex-wrap: wrap; }
  .CreditInfo_CreditInfo__2zSj5 > .CreditInfo_checkBlock__bSGnu > article {
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    font-size: 3.84vw;
    line-height: 4.94vw;
    margin-bottom: 3.47vw; }
  .CreditInfo_CreditInfo__2zSj5 > .CreditInfo_checkBlock__bSGnu > article > img {
    width: 4.78vw;
    min-width: 4.78vw;
    height: 4.78vw;
    margin-right: 5.21vw;
    margin-bottom: 0; } }

.Tabber_Tabber__lHUiV > header {
  display: flex;
  cursor: pointer; }

.Tabber_Tabber__lHUiV > div {
  transition: 0.2s;
  margin-top: 3.64vw; }

.Tabber_Tabber__lHUiV > header > div {
  width: 50%;
  height: 6.56vw;
  line-height: 6.56vw;
  text-align: center;
  font-size: 2.04vw;
  font-weight: bold;
  border-bottom: 0.24vw solid #dcdcdc; }

.Tabber_activeLeftHeader__3CWKg > div:first-child {
  height: 7vw;
  line-height: 6.56vw;
  border-bottom: 0 !important;
  border-right: 0.24vw solid #dcdcdc;
  border-top: 0.24vw solid #dcdcdc;
  border-left: 0.24vw solid #dcdcdc;
  border-top-right-radius: 0.72vw;
  border-top-left-radius: 0.72vw; }

.Tabber_activeLeftHeader__3CWKg > div:last-child {
  margin-top: 1vw;
  line-height: 5vw;
  margin-left: -0.24vw;
  border-left: 0.24vw solid #dcdcdc;
  border-bottom-left-radius: 0.72vw; }

.Tabber_activeRightHeader__3rSul > div:first-child {
  margin-top: 1vw;
  line-height: 5vw;
  margin-right: -0.24vw;
  border-right: 0.24vw solid #dcdcdc;
  border-bottom-right-radius: 0.72vw; }

.Tabber_activeRightHeader__3rSul > div:last-child {
  height: 7vw;
  line-height: 6.56vw;
  border-bottom: 0 !important;
  border-left: 0.24vw solid #dcdcdc;
  border-top: 0.24vw solid #dcdcdc;
  border-right: 0.24vw solid #dcdcdc;
  border-top-left-radius: 0.72vw;
  border-top-right-radius: 0.72vw; }

@media (max-width: 900px) {
  .Tabber_Tabber__lHUiV > header {
    flex-direction: column; }
  .Tabber_Tabber__lHUiV > div {
    margin-top: 6.04vw; }
  .Tabber_Tabber__lHUiV > header > div {
    width: 100%;
    height: 10.16vw;
    line-height: 9.16vw !important;
    text-align: left;
    font-size: 3.84vw;
    border-radius: 1.37vw !important;
    font-weight: bold;
    color: #dcdcdc;
    border: 0.54vw solid #dcdcdc !important;
    margin: 0 !important;
    padding: 0 4.94vw; }
  .Tabber_activeLeftHeader__3CWKg > div:first-child {
    height: 10.16vw;
    line-height: 9.16vw;
    color: black;
    border: 0.54vw solid black !important; }
  .Tabber_activeLeftHeader__3CWKg > div:last-child {
    margin-top: initial;
    line-height: 9.16vw; }
  .Tabber_activeRightHeader__3rSul > div:first-child {
    margin-top: 0;
    line-height: 9.16vw; }
  .Tabber_activeRightHeader__3rSul > div:last-child {
    height: 10.16vw;
    line-height: 9.16vw;
    color: black;
    border: 0.54vw solid black !important; }
  .Tabber_Tabber__lHUiV > header > div:first-child {
    margin-bottom: 2.2vw !important; } }

@media (max-width: 460px) {
  .Tabber_Tabber__lHUiV > div {
    margin-top: 9.56vw; }
  .Tabber_Tabber__lHUiV > header > div {
    height: 16.08vw;
    line-height: 14vw !important;
    text-align: left;
    font-size: 3.91vw;
    border-radius: 2.17vw !important;
    border: 0.86vw solid #dcdcdc !important;
    margin: 0 !important;
    padding: 0 7.82vw; }
  .Tabber_activeLeftHeader__3CWKg > div:first-child {
    height: 16.08vw;
    line-height: 14vw;
    border: 0.84vw solid black !important; }
  .Tabber_activeLeftHeader__3CWKg > div:last-child {
    line-height: 16.08vw; }
  .Tabber_activeRightHeader__3rSul > div:first-child {
    margin-top: 0;
    line-height: 14vw; }
  .Tabber_activeRightHeader__3rSul > div:last-child {
    height: 16.08vw;
    line-height: 14vw;
    border: 0.84vw solid black !important; }
  .Tabber_Tabber__lHUiV > header > div:first-child {
    margin-bottom: 3.47vw !important; } }

.About_About__ab3bl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 1.31vw; }

.About_About__ab3bl > img {
  width: 100%;
  height: 34.16vw;
  margin-bottom: 6.56vw; }

.About_About__ab3bl > div:nth-child(2) {
  width: 100%; }

.About_About__ab3bl > div:nth-child(2) > div > a {
  font-weight: bold;
  cursor: pointer;
  color: #005ba8;
  border-bottom: 0.1vw solid #005ba8; }

.About_About__ab3bl > div:nth-child(3) {
  width: 18.24vw; }

.About_About__ab3bl > div:nth-child(3) > article {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }

.About_About__ab3bl > div:nth-child(3) > article > div > h4 {
  font-size: 2.04vw;
  line-height: 2.48vw;
  color: black;
  font-weight: bold; }

.About_About__ab3bl > div:nth-child(2) > p,
.About_About__ab3bl > div:nth-child(3) > article > div > p {
  font-size: 1.31vw;
  line-height: 1.89vw; }

.About_About__ab3bl > div:nth-child(2) > p > span {
  cursor: pointer; }

.About_About__ab3bl > div:nth-child(2) > p > span > span {
  color: red; }

.About_About__ab3bl > div:nth-child(2) > p > span:hover,
.About_About__ab3bl > div:nth-child(2) > p > span:hover > span {
  color: #005ba8; }

.About_About__ab3bl > div:nth-child(3) > article > div > p {
  line-height: 2.04vw; }

.About_About__ab3bl > div:nth-child(3) > article:first-child > picture {
  width: 6.93vw; }

.About_About__ab3bl > div:nth-child(3) > article > picture > img {
  width: 100%; }

.About_About__ab3bl > div:nth-child(3) > article:last-child > img {
  width: 7.88vw; }

.About_info__31xyU {
  padding: 4.96vw 10.21vw 2.91vw; }

.About_info__31xyU {
  text-align: center; }

.About_info__31xyU > p {
  margin: 0;
  font-size: 1.31vw;
  line-height: 2.04vw; }

.About_info__31xyU > div {
  width: 13.43vw;
  margin: 2.62vw auto 0; }

@media (max-width: 900px) {
  .About_About__ab3bl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 2.47vw; }
  .About_About__ab3bl > img {
    height: 39.56vw;
    margin-bottom: 10.98vw; }
  .About_About__ab3bl > div:nth-child(2) {
    width: 100%; }
  .About_About__ab3bl > div:nth-child(2) > div > a {
    border-bottom: 0.1vw solid #005ba8; }
  .About_About__ab3bl > div:nth-child(3) {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 8vw; }
  .About_About__ab3bl > div:nth-child(3) > article {
    width: 40vw; }
  .About_About__ab3bl > div:nth-child(3) > article > div > h4 {
    font-size: 3.84vw;
    line-height: 4.67vw; }
  .About_About__ab3bl > div:nth-child(2) > p,
  .About_About__ab3bl > div:nth-child(3) > article > div > p {
    font-size: 2.47vw !important;
    line-height: 3.3vw; }
  .About_About__ab3bl > div:nth-child(3) > article > div > p {
    line-height: 3.3vw; }
  .About_About__ab3bl > div:nth-child(3) > article:first-child > picture {
    width: 13.04vw; }
  .About_About__ab3bl > div:nth-child(3) > article:last-child > img {
    width: 12.36vw; }
  .About_info__31xyU {
    padding: 9.96vw 10.21vw 9.96vw; }
  .About_info__31xyU > p {
    font-size: 2.47vw;
    line-height: 4.39vw; }
  .About_info__31xyU > div {
    width: 40vw;
    margin: 7.62vw auto 0; } }

@media (max-width: 460px) {
  .About_About__ab3bl {
    font-size: 3.91vw; }
  .About_About__ab3bl > img {
    height: 39.13vw;
    margin-bottom: 10.98vw; }
  .About_About__ab3bl > div:nth-child(2),
  .About_About__ab3bl > div:nth-child(3) {
    margin-bottom: 8.69vw; }
  .About_About__ab3bl > div:nth-child(2) > div > a {
    border-bottom: 0.2vw solid #005ba8; }
  .About_About__ab3bl > div:nth-child(3) {
    flex-direction: column; }
  .About_About__ab3bl > div:nth-child(3) > article {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    width: 100%; }
  .About_About__ab3bl > div:nth-child(3) > article > div {
    width: 100%; }
  .About_About__ab3bl > div:nth-child(3) > article > div > h4 {
    font-size: 6.08vw;
    line-height: 7.39vw; }
  .About_About__ab3bl > div:nth-child(2) > p,
  .About_About__ab3bl > div:nth-child(3) > article > div > p {
    font-size: 3.91vw !important;
    line-height: 5.21vw; }
  .About_About__ab3bl > div:nth-child(3) > article > img,
  .About_About__ab3bl > div:nth-child(3) > article > picture {
    margin-right: 9.56vw; }
  .About_About__ab3bl > div:nth-child(3) > article:first-child > picture {
    width: 20.65vw;
    min-width: 20.65vw; }
  .About_About__ab3bl > div:nth-child(3) > article:last-child > img {
    width: 20.65vw;
    min-width: 20.65vw; }
  .About_info__31xyU {
    padding: 9.96vw 10.21vw 9.96vw; }
  .About_info__31xyU > p {
    font-size: 3.91vw;
    line-height: 5.04vw; }
  .About_info__31xyU > div {
    width: 40vw;
    margin: 7.62vw auto 0; } }

.Contacts_contactsInfo__2nrdg {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.Contacts_contactsInfo__2nrdg > article:first-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 4.34vw; }

.Contacts_contacts__2MDiI {
  display: flex;
  align-items: center; }

.Contacts_contacts__2MDiI > div:first-child {
  display: flex;
  align-items: center;
  min-width: 16.93vw;
  font-size: 1.89vw;
  margin-right: 1.45vw; }

.Contacts_contacts__2MDiI > div:first-child > img {
  width: 1.09vw;
  height: 1.45vw;
  margin-right: 0.87vw; }

.Contacts_contacts__2MDiI > div:first-child > a {
  cursor: pointer;
  color: #000;
  text-decoration: none;
  line-height: 2.48vw; }

.Contacts_contacts__2MDiI > div:last-child {
  font-size: 1.31vw;
  color: #767676; }

.Contacts_address__gSZY6 {
  display: flex;
  font-size: 1.31vw;
  color: black; }

.Contacts_address__gSZY6 > div > img {
  width: 1.6vw;
  height: 1.31vw;
  margin-right: 0.43vw;
  vertical-align: baseline; }

.Contacts_address__gSZY6 > span {
  font-weight: bold;
  margin-right: 1.45vw; }

.Contacts_contactsInfo__2nrdg > article:last-child {
  display: flex;
  align-items: center;
  width: 27.15vw;
  border: 0.29vw solid #005ba8;
  border-radius: 0.72vw;
  padding: 0.72vw 1.6vw;
  max-height: 5.54vw; }

.Contacts_contactsInfo__2nrdg > article:last-child > img {
  width: 2.62vw;
  margin-right: 1.89vw; }

.Contacts_contactsInfo__2nrdg > article:last-child > p {
  margin: 0;
  font-size: 1.31vw; }

.Contacts_map__xB06A {
  width: 100%;
  height: 33.86vw;
  margin-top: 4.08vw;
  margin-bottom: 5.62vw; }

.Contacts_nowrap__1Hd1C {
  white-space: nowrap; }

.Contacts_disableMoscow__373tN > header > div:last-child {
  color: lightgray;
  cursor: initial;
  pointer-events: none; }

@media (max-width: 900px) {
  .Contacts_contactsInfo__2nrdg > article:first-child {
    min-height: 17.3vw; }
  .Contacts_contacts__2MDiI {
    display: flex;
    align-items: flex-start;
    flex-direction: column; }
  .Contacts_contacts__2MDiI > div:first-child {
    min-width: 16.93vw;
    font-size: 3.57vw;
    line-height: 4.67vw;
    margin-right: 2.74vw; }
  .Contacts_contacts__2MDiI > div:first-child > img {
    width: 2.47vw;
    height: 3.84vw;
    margin-right: 1.64vw; }
  .Contacts_contacts__2MDiI > div:first-child > a {
    line-height: 4.67vw; }
  .Contacts_contacts__2MDiI > div:last-child {
    font-size: 2.47vw; }
  .Contacts_address__gSZY6 {
    flex-direction: column;
    font-size: 2.47vw; }
  .Contacts_address__gSZY6 > div > img {
    width: 3.02vw;
    height: 2.47vw;
    margin-right: 0.82vw; }
  .Contacts_address__gSZY6 > span {
    margin-right: 2.74vw; }
  .Contacts_contactsInfo__2nrdg {
    align-items: flex-start; }
  .Contacts_contactsInfo__2nrdg > article:last-child {
    width: 46.7vw;
    border: 0.43vw solid #005ba8;
    border-radius: 1.37vw;
    padding: 1.37vw 3.02vw;
    min-height: 10.16vw; }
  .Contacts_contactsInfo__2nrdg > article:last-child > img {
    width: 4.94vw;
    margin-right: 3.57vw; }
  .Contacts_contactsInfo__2nrdg > article:last-child > p {
    font-size: 2.47vw; }
  .Contacts_map__xB06A {
    width: 100%;
    height: 63.73vw;
    margin-top: 4.08vw;
    margin-bottom: 5.62vw; } }

@media (max-width: 460px) {
  .Contacts_contactsInfo__2nrdg > article:first-child {
    min-height: 17.3vw; }
  .Contacts_contacts__2MDiI {
    margin-bottom: 5.65vw; }
  .Contacts_contacts__2MDiI > div:first-child {
    min-width: 16.93vw;
    font-size: 5.65vw;
    line-height: 7.39vw;
    margin-right: 0; }
  .Contacts_contacts__2MDiI > div:first-child > img {
    width: 3.91vw;
    height: 6.08vw;
    margin-right: 2.6vw; }
  .Contacts_contacts__2MDiI > div:first-child > a {
    line-height: 7.39vw; }
  .Contacts_contacts__2MDiI > div:last-child {
    font-size: 3.91vw; }
  .Contacts_address__gSZY6 {
    font-size: 3.91vw;
    margin-bottom: 5.65vw; }
  .Contacts_address__gSZY6 > div > img {
    width: 4.78vw;
    height: 3.91vw;
    margin-right: 1.3vw; }
  .Contacts_address__gSZY6 > span {
    margin-right: 4.34vw; }
  .Contacts_contactsInfo__2nrdg {
    flex-direction: column;
    align-items: flex-start; }
  .Contacts_contactsInfo__2nrdg > article:last-child {
    width: 100%;
    border: 0.6vw solid #005ba8;
    border-radius: 2.17vw;
    padding: 1.37vw 5.21vw;
    min-height: 16.08vw; }
  .Contacts_contactsInfo__2nrdg > article:last-child > img {
    width: 7.82vw;
    margin-right: 5.65vw; }
  .Contacts_contactsInfo__2nrdg > article:last-child > p {
    font-size: 3.91vw; }
  .Contacts_map__xB06A {
    width: 100%;
    height: 100vw;
    margin-top: 9.56vw;
    margin-bottom: 8.69vw; } }

.Testimonials_anchor__1DYnf {
  position: relative;
  top: -20vw; }

.Testimonials_Testimonials__1rt-C {
  background-color: #f1f1f1;
  border-radius: 0.72vw; }

.Testimonials_video__18-2A {
  padding: 10px;
  text-align: center;
  height: 15vw; }

.Testimonials_video__18-2A > div {
  width: 100% !important;
  height: 100% !important; }

.Testimonials_video__18-2A iframe {
  border-radius: 0.72vw; }

.Testimonials_video__18-2A video {
  border-radius: 0.72vw;
  width: 100% !important;
  height: 100% !important; }

@media (max-width: 900px) {
  .Testimonials_video__18-2A {
    height: 50vw !important; } }

@media (max-width: 460px) {
  .Testimonials_video__18-2A {
    height: 50vw !important; } }

.SectionWrapper_SectionWrapper__Baz_a {
  padding-top: 6.02vw; }

.SectionWrapper_SectionWrapper__Baz_a > h2 {
  font-size: 3.35vw;
  color: black;
  font-weight: bold;
  line-height: 3.64vw;
  padding: 0;
  margin: 0 0 4.37vw 0; }

@media (max-width: 900px) {
  .SectionWrapper_SectionWrapper__Baz_a {
    padding-top: 7.55vw; }
  .SectionWrapper_SectionWrapper__Baz_a > h2 {
    font-size: 6.18vw;
    line-height: 7.5vw;
    margin: 0 0 6.04vw 0; } }

@media (max-width: 460px) {
  .SectionWrapper_SectionWrapper__Baz_a {
    padding-top: 10vw; }
  .SectionWrapper_SectionWrapper__Baz_a > h2 {
    font-size: 7.82vw;
    line-height: 9.56vw;
    margin: 0 0 6.52vw 0; } }

.DocsPanel_docsPanel__3CpWg > article > span {
  font-size: 1.15vw; }

.DocsPanel_docsPanel__3CpWg {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: flex-start;
  padding-bottom: 1.34vw;
  margin-bottom: 2.89vw;
  border-bottom: 0.15vw solid rgba(9, 179, 170, 0.1); }

.DocsPanel_docsPanel__3CpWg > article {
  display: flex;
  width: 13.91vw;
  height: 30vw;
  justify-content: space-between;
  align-items: center;
  flex-direction: column; }

.DocsPanel_docsPanel__3CpWg > article > div {
  position: relative;
  cursor: pointer;
  margin-bottom: 2vw; }

.DocsPanel_docsPanel__3CpWg > article > div > img {
  width: 100%;
  transition: 0.1s ease-in; }

.DocsPanel_docsPanel__3CpWg > article > div > .DocsPanel_shower__2XNhA {
  position: absolute;
  opacity: 0;
  transition: 0.1s ease-in;
  z-index: 10;
  width: 2.89vw;
  height: 2.89vw;
  left: calc(100% / 2 - 1.445vw);
  top: calc(100% / 2 - 1.445vw); }

.DocsPanel_docsPanel__3CpWg > article > div:hover > img:last-child {
  opacity: 0.5; }

.DocsPanel_docsPanel__3CpWg > article > div:hover > .DocsPanel_shower__2XNhA {
  opacity: 1; }

.DocsPanel_docsPanel__3CpWg > article > span {
  flex: 1 1; }

.DocsPanel_modalDocument__3ADP0 {
  width: 48vw !important; }

.DocsPanel_modalDocument__3ADP0 .ant-modal-body {
  padding: 4.34vw 4.8vw !important; }

.DocsPanel_document__MZeCP > h1 {
  text-align: center;
  font-size: 2.6vw;
  line-height: 3.15vw;
  margin: 0 -1vw 2.75vw; }

.DocsPanel_document__MZeCP > img {
  width: 100%; }

@media (max-width: 900px) {
  .DocsPanel_docs__1Rj9I {
    padding: 0 3.75%;
    margin: 6vw 0; }
  .DocsPanel_docs__1Rj9I > h2 {
    font-size: 3vw; }
  .DocsPanel_docsPanel__3CpWg > article > span {
    font-size: 2vw; }
  .DocsPanel_docsPanel__3CpWg > article > span {
    line-height: 2.5vw; }
  .DocsPanel_docsPanel__3CpWg {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0;
    margin-bottom: 5vw;
    border-bottom: 0.25vw solid rgba(9, 179, 170, 0.1); }
  .DocsPanel_docsPanel__3CpWg > article {
    width: 32vw;
    padding: 0 3.5vw;
    height: 47.625vw;
    margin-bottom: 5vw; }
  .DocsPanel_docsPanel__3CpWg > article > div > .DocsPanel_shower__2XNhA {
    width: 5vw;
    height: 5vw;
    left: calc(100% / 2 - 2.5vw);
    top: calc(100% / 2 - 2.5vw); }
  .DocsPanel_docsPanel__3CpWg > article > span {
    height: 9.375vw; }
  .DocsPanel_modalDocument__3ADP0 {
    width: 80vw !important; }
  .DocsPanel_modalDocument__3ADP0 .ant-modal-body {
    padding: 4vw 5vw !important; }
  .DocsPanel_document__MZeCP > h1 {
    font-size: 4.6vw;
    line-height: 5.2vw;
    margin-bottom: 5vw; } }

@media (max-width: 460px) {
  .DocsPanel_docsPanel__3CpWg {
    border-bottom: 0.43vw solid rgba(9, 179, 170, 0.1);
    padding-bottom: 4vw;
    margin-bottom: 10vw; }
  .DocsPanel_docsPanel__3CpWg > article > div > .DocsPanel_shower__2XNhA {
    width: 8.69vw;
    height: 8.69vw;
    left: calc(100% / 2 - 4.345vw);
    top: calc(100% / 2 - 4.345vw); }
  .DocsPanel_docsPanel__3CpWg > article > span {
    font-size: 3.47vw; }
  .DocsPanel_docsPanel__3CpWg > article > span {
    line-height: 4.5vw; }
  .DocsPanel_docsPanel__3CpWg > article {
    width: 40.34vw;
    height: 77.82vw;
    padding: 0;
    margin-bottom: 8.69vw; }
  .DocsPanel_docsPanel__3CpWg > article > span {
    height: 16.3vw; }
  .DocsPanel_modalDocument__3ADP0 {
    width: 91vw !important; }
  .DocsPanel_modalDocument__3ADP0 .ant-modal-body {
    padding: 6.95vw 8.69vw !important; }
  .DocsPanel_document__MZeCP > h1 {
    font-size: 7.82vw;
    line-height: 8.3vw;
    margin-bottom: 8.69vw; } }

.Info_Info__kYNu2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8.46vw;
  flex-direction: column; }

/*.Info :global(p)
    margin: 0
    font-size: 0.8vw
    color: #767676*/
.Info_condition__17XB7 p {
  text-align: justify;
  margin: 0;
  font-size: 0.8vw;
  color: #767676; }

.Info_Info__kYNu2 > div:first-child > article {
  margin-bottom: 3.35vw; }

.Info_Info__kYNu2 > div:first-child > article:last-child {
  margin-bottom: 0; }

.Info_Info__kYNu2 > div:first-child > article > h4,
.Info_fileList__1rBY4 > h4 {
  color: black;
  font-size: 2.04vw;
  line-height: 2.62vw;
  margin-bottom: 1.6vw; }

.Info_fileList__1rBY4 > ul > li {
  margin-bottom: 0.43vw; }

.Info_fileList__1rBY4 > ul > li,
.Info_fileList__1rBY4 > ul > li:last-child > div {
  display: flex; }

.Info_fileList__1rBY4 > ul > li > img,
.Info_fileList__1rBY4 > ul > li:last-child > div > img {
  width: 0.94vw;
  min-width: 0.94vw;
  height: 1.16vw;
  margin-top: 0.43vw;
  margin-right: 1.16vw; }

.Info_fileList__1rBY4 > ul > li > a,
.Info_fileList__1rBY4 > ul > li > div > a,
.Info_fileList__1rBY4 > ul > li > p {
  font-size: 1.16vw; }

.Info_fileList__1rBY4 > ul > li > a,
.Info_fileList__1rBY4 > ul > li:last-child > div > a {
  line-height: 2.18vw;
  display: inline;
  color: black; }

.Info_fileList__1rBY4 > ul > li > a > span,
.Info_fileList__1rBY4 > ul > li:last-child > div > a > span {
  margin-right: 0.43vw;
  color: #005ba8;
  border-bottom: 0.1vw solid #005ba8; }

.Info_fileList__1rBY4 > ul > li:last-child {
  margin-top: 4.08vw;
  flex-direction: column;
  align-items: flex-start; }

.Info_fileList__1rBY4 ul {
  -webkit-column-count: 2;
          column-count: 2;
  margin-bottom: 5vw; }

@media (max-width: 900px) {
  .Info_fileList__1rBY4 ul {
    -webkit-column-count: 2;
            column-count: 2;
    margin-bottom: 5vw; }
  .Info_Info__kYNu2 {
    margin-bottom: 10vw;
    flex-direction: column; }
  .Info_Info__kYNu2 p {
    margin: 0;
    font-size: 1.47vw; }
  .Info_Info__kYNu2 > div:first-child {
    width: 100%; }
  .Info_Info__kYNu2 > div:last-child {
    width: 100%; }
  .Info_Info__kYNu2 > div:first-child > article {
    margin-bottom: 6.04vw; }
  .Info_Info__kYNu2 > div:first-child > article > h4,
  .Info_fileList__1rBY4 > h4 {
    font-size: 3.84vw;
    line-height: 4.94vw;
    margin-bottom: 3.02vw; }
  .Info_fileList__1rBY4 {
    margin-top: 6.04vw; }
  .Info_fileList__1rBY4 > ul > li {
    margin-bottom: 0.82vw; }
  .Info_fileList__1rBY4 > ul > li > img,
  .Info_fileList__1rBY4 > ul > li:last-child > div > img {
    width: 1.78vw;
    min-width: 1.78vw;
    height: 2.2vw;
    margin-top: 0.82vw;
    margin-right: 2.2vw; }
  .Info_fileList__1rBY4 > ul > li > a,
  .Info_fileList__1rBY4 > ul > li > div > a,
  .Info_fileList__1rBY4 > ul > li > p {
    font-size: 2.2vw; }
  .Info_fileList__1rBY4 > ul > li > a,
  .Info_fileList__1rBY4 > ul > li:last-child > div > a {
    line-height: 4.12vw; }
  .Info_fileList__1rBY4 > ul > li > a > span,
  .Info_fileList__1rBY4 > ul > li:last-child > div > a > span {
    margin-right: 0.82vw;
    border-bottom: 0.1vw solid #005ba8; }
  .Info_fileList__1rBY4 > ul > li:last-child {
    margin-top: 4vw;
    flex-direction: column;
    align-items: flex-start; } }

@media (max-width: 460px) {
  .Info_fileList__1rBY4 ul {
    -webkit-column-count: 1;
            column-count: 1;
    margin-bottom: 5vw; }
  .Info_Info__kYNu2 {
    margin-bottom: 14vw; }
  .Info_Info__kYNu2 p {
    font-size: 2.6vw; }
  .Info_Info__kYNu2 > div:first-child > article {
    margin-bottom: 9.56vw; }
  .Info_Info__kYNu2 > div:first-child > article > h4,
  .Info_fileList__1rBY4 > h4 {
    font-size: 6.08vw;
    line-height: 7.82vw;
    margin-bottom: 4.78vw; }
  .Info_fileList__1rBY4 {
    margin-top: 9.56vw; }
  .Info_fileList__1rBY4 > ul > li {
    margin-bottom: 1.3vw; }
  .Info_fileList__1rBY4 > ul > li > img,
  .Info_fileList__1rBY4 > ul > li:last-child > div > img {
    width: 2.82vw;
    min-width: 2.82vw;
    height: 3.47vw;
    margin-top: 1.3vw;
    margin-right: 3.47vw; }
  .Info_fileList__1rBY4 > ul > li > a,
  .Info_fileList__1rBY4 > ul > li > div > a,
  .Info_fileList__1rBY4 > ul > li > p {
    font-size: 3.47vw; }
  .Info_fileList__1rBY4 > ul > li > a,
  .Info_fileList__1rBY4 > ul > li:last-child > div > a {
    line-height: 6.52vw; }
  .Info_fileList__1rBY4 > ul > li > a > span,
  .Info_fileList__1rBY4 > ul > li:last-child > div > a > span {
    margin-right: 1.3vw;
    border-bottom: 0.2vw solid #005ba8; }
  .Info_fileList__1rBY4 > ul > li:last-child {
    margin-top: 7.39vw; } }

.Info_companyInfo__3oB6c {
  display: flex;
  justify-content: space-between;
  /*grid-column-gap: 190px*/
  margin-top: 35px;
  margin-bottom: 35px; }

.Info_companyInfo__3oB6c > div:first-child {
  width: 34vw;
  display: flex;
  flex-direction: column; }

.Info_companyInfo__3oB6c > div:first-child > img {
  width: 80%;
  margin-bottom: 15px; }

.Info_companyTitle__ZtUXY {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  font-family: OpenSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif; }

.Info_companyInfo__3oB6c > div:last-child {
  width: 34vw; }

.Info_companyInfo__3oB6c > div:last-child p {
  font-family: OpenSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.16vw;
  margin-bottom: 0; }

@media (max-width: 900px) {
  .Info_companyInfo__3oB6c {
    flex-direction: column;
    margin-bottom: 0; }
  .Info_companyInfo__3oB6c > div {
    width: 100% !important;
    margin-bottom: 35px; }
  .Info_companyInfo__3oB6c > div:last-child p {
    font-size: 2.47vw; } }

@media (max-width: 460px) {
  .Info_companyInfo__3oB6c > div:last-child p {
    font-size: 3.91vw; } }

.Info_docsBlock__AROMn {
  display: flex;
  align-items: flex-start;
  grid-column-gap: 86px;
  margin-bottom: 40px; }

.Info_docsList__1K6LL {
  display: flex;
  flex-direction: column;
  width: 50%;
  grid-row-gap: 6px;
  margin-bottom: 0; }

.Info_docsList__1K6LL li {
  /*display: flex*/
  /*align-items: center*/
  max-width: 520px; }

.Info_docsList__1K6LL li > a {
  display: flex;
  align-items: baseline; }

.Info_docsList__1K6LL li img {
  width: 13px;
  height: 16px;
  margin-right: 15.5px; }

.Info_docsList__1K6LL li span {
  max-width: 490px;
  font-family: OpenSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline; }

.Info_docsSubList__1U2PA {
  display: none;
  margin-left: 30px;
  margin-top: 6px; }

@media (max-width: 900px) {
  .Info_docsBlock__AROMn {
    flex-direction: column; }
  .Info_docsList__1K6LL {
    width: 100%; } }

h1.Info_financeInfoTitle__1DdH1, h1.Info_sroInfoTitle__1z867, h1.Info_clientsInfoTitle__35YE5 {
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: .5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-family: OpenSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif; }

.Info_infoTable__a93AW {
  display: none;
  width: 100%;
  margin-bottom: 33px; }

.Info_infoTable__a93AW tr {
  vertical-align: baseline; }

.Info_infoTable__a93AW > tr:not(:last-child) td {
  padding-bottom: 5px; }

.Info_infoTable__a93AW > tr td:first-child {
  width: 60%;
  padding-right: 40px; }

.Info_infoTable__a93AW > tr td:last-child {
  /*width: 497px*/ }

.Info_footNote__1VSpU {
  width: 100%;
  margin-top: 28px; }

.Info_footNote__1VSpU p {
  text-align: justify;
  margin: 0;
  font-size: 8px;
  color: #767676;
  font-family: OpenSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif; }

.Info_infoListGroup__2JOml {
  display: flex;
  justify-content: space-between;
  grid-column-gap: 86px;
  margin-bottom: 40px; }

.Info_infoListGroup__2JOml > .Info_infoListBlock__2BVdI {
  width: 50%; }

.Info_infoLastBlock__3msOv {
  margin-bottom: 100px; }

.Info_infoList__1iju7 > li, .Info_infoLastBlock__3msOv > p {
  margin-bottom: 8px;
  font-size: 1.16vw;
  font-family: OpenSans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif; }

@media (max-width: 900px) {
  .Info_infoListGroup__2JOml {
    flex-wrap: wrap; }
  .Info_infoListGroup__2JOml > .Info_infoListBlock__2BVdI {
    width: 100%; }
  .Info_infoListGroup__2JOml > .Info_infoListBlock__2BVdI:not(:first-child) {
    margin-top: 6.04vw; }
  .Info_infoList__1iju7 > li, .Info_infoLastBlock__3msOv > p {
    font-size: 2.47vw; } }

@media (max-width: 460px) {
  .Info_infoList__1iju7 > li, .Info_infoLastBlock__3msOv > p {
    font-size: 3.91vw; } }


/* Slider */


.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Icons */
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '\2190';
}
[dir='rtl'] .slick-prev:before
{
    content: '\2192';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '\2192';
}
[dir='rtl'] .slick-next:before
{
    content: '\2190';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '\2022';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

/*

Здесь задаются глобальные стили для всего проекта,
а также переопределяется поведение стилей библиотеки Ant Design

*/

@font-face {
  font-family: OpenSans;
  src: url(/static/media/OpenSans-Regular.d7d5d458.ttf);
}

/*@font-face {
  font-family: OpenSansBold;
  src: url("./assets/fonts/Open_Sans/OpenSans-Bold.ttf");
}
@font-face {
  font-family: OpenSansSemiBold;
  src: url("./assets/fonts/Open_Sans/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: PTSans;
  src: url("./assets/fonts/PT_Sans/PT_Sans-Web-Regular.ttf");
}

@font-face {
  font-family: PTSansBold;
  src: url("./assets/fonts/PT_Sans/PT_Sans-Web-Bold.ttf");
}*/

body {
  margin: 0;
  padding: 0;
  font-family: OpenSans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: OpenSans, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a, a:hover, a:focus, a:visited {
  text-decoration: none;
}

/*div > ymaps > ymaps > ymaps > ymaps:nth-child(2) {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.1 0.5 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); !* Firefox 3.5+ *!
  -webkit-filter: grayscale(100%); !* Chrome 19+ & Safari 6+ *!
}*/

.whiteInput {
  border-radius: 0.72vw !important;
  height: 3.94vw !important;
  border: 0 solid white !important;
  transition: 0.2s;
}

.whiteInput > div > input {
  height: 3.94vw !important;
  padding: 0.72vw 2.91vw !important;
  font-size: 1.31vw;
}

.whiteInput:hover,
.whiteInput:focus,
.whiteInput:active,
.ant-input-number-focused:hover,
.ant-input-number-focused:focus,
.ant-input-number-focused:active,
.ant-input-number-input:hover,
.ant-input-number-input:focus,
.ant-input-number-input:active {
  outline: 0 !important;
  box-shadow: initial !important;
  border: 0 !important;
}

.ant-slider-handle:focus {
  border-color: #06ae35 !important;
  box-shadow: 0 0 0 5px #06ae35 !important;
}

.ant-slider-handle {
  border: solid 2px #06ae35 !important;
  background-color: #06ae35 !important;
  background-repeat: no-repeat;
  background-position: -3px -3px;
  margin-left: -12px;
  margin-top: -10px;
  width: 25px;
  height: 25px;
}

.ant-slider-rail, .ant-slider-dot {
  background-color: #e6f7f6;
}

.ant-slider-handle:focus {
  box-shadow: initial !important;
}

.ant-slider-handle:focus {
  box-shadow: initial !important;
  outline: initial !important;
}

.ant-slider:hover .ant-slider-track {
  background-color: #06ae35;
}

.ant-slider-track, .ant-slider-track:hover, .ant-slider-track:focus, .ant-slider-track:active {
  background-color: #06ae35;
}

.ant-slider-dot-active {
  background-color: #06ae35 !important;
  border-color: #06ae35 !important;
}

.ant-slider-dot {
  border: inherit;
  border-radius: inherit;
  height: 10px;
  width: 2px;
}

.ant-slider-dot {
  height: 0;
}

.ant-slider-dot:nth-child(2n) {
  top: -3px;
}

.ant-slider-dot:nth-child(2n+1) {
  top: -2px;
}

.ant-slider-dot:first-child, .ant-slider-dot:last-child {
  margin-left: -2px;
  height: 10px;
  top: -3px;
}

.ant-slider-dot:nth-child(5) {
  margin-left: -2px;
  top: -3px;
}

.ant-modal {
  margin: 0 auto !important;
  width: 67.44vw !important;
}

.ant-modal-footer, .ant-modal-header {
  display: none;
}

.ant-modal-close {
  display: none;
}

.ant-modal-close > span {
  width: inherit;
  height: inherit;
  line-height: 30px;
  color: white;
}

.ant-modal > div {
  border-radius: 10px !important;
  background-color: #f1f1f1;
}

.ant-modal-body {
  padding: 0 !important;
}

/*.ant-modal-body > * {
  background-color: #f1f1f1;
}*/

.disabledLink {
  opacity: 0.5;
  cursor: initial;
  pointer-events: none;
}

.ant-input-number {
  width: 100%;
}

.ant-input-number-focused {
  box-shadow: initial;
}

.ant-input-number-handler-wrap {
  display: none;
}

@media (max-width: 900px) {
  .whiteInput {
    border-radius: 1.37vw !important;
    height: 7.41vw !important;
  }

  .whiteInput > div > input {
    text-align: center;
    height: 7.41vw !important;
    font-size: 2.47vw;
  }

  .ant-modal {
    width: 92vw !important;
  }
}

@media (max-width: 460px) {
  .whiteInput {
    border-radius: 2.17vw !important;
    height: 11.73vw !important;
  }

  .whiteInput > div > input {
    height: 11.73vw !important;
    font-size: 3.91vw;
  }

}






