@import "../../config.sass";

.Header {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 8.02vw;
  z-index: 210;
  margin: 0 !important;
  padding: 0 9.48vw; }

.showMargin {
  top: 4.34vw; }

.Header > .logo {
  width: 17vw; }

.logo > picture > img {
  width: 100%;
  height: auto;
  cursor: pointer; }

.Header > ul {
  display: flex;
  justify-content: space-between;
  width: 28vw;
  transition: 0.2s; }

.menuList > li > a,
.Header > ul > li > a {
  color: black;
  transition: 0.2s; }

.Header > ul > li > a {
  font-size: 1.31vw; }

.menuList > li > a:hover,
.Header > ul > li > a:hover {
  color: $blue-color; }

.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.contacts > div:first-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 16.93vw;
  font-size: 1.89vw; }

.contacts > div:first-child > img {
  width: 1.09vw;
  height: 1.45vw;
  margin-right: 0.87vw; }

.contacts > div:first-child > a {
  cursor: pointer;
  color: #000;
  text-decoration: none;
  line-height: 2.48vw; }

.contacts > div:last-child {
  font-size: 0.87vw;
  color: #767676; }

.menu {
  display: none; }

.headerButton {
  opacity: 0;
  width: 13.5vw !important;
  height: 2.62vw !important;
  font-size: 1.02vw !important;
  line-height: 2.62vw !important; }

.modalMenu > div {
  width: 40.43vw !important; }

.stickyHeader {
  position: fixed;
  top: 0;
  width: 100% !important;
  margin: 0 !important;
  background-color: white;
  transition: 0.2s, color 0.1s ease-in, background-color 0.1s ease-in; }

.stickyHeader > .headerButton {
  display: initial; }

.stickyHeader > ul {
  width: 22vw; }

.stickyHeader > ul > li > a {
  font-size: 1.02vw; }


@media (max-width: $tablet-width) {
  .Header {
    height: 15.1vw;
    padding: 0 4.12vw; }

  .showMargin {
    top: 7.5vw; }

  .Header > .logo {
    width: 32.14vw; }

  .Header > ul,
  .Header > .headerButton {
    display: none; }

  .contacts > div:first-child {
    min-width: 32.14vw;
    font-size: 3.84vw; }

  .contacts > div:first-child > img {
    width: 2.06vw;
    height: 2.74vw;
    margin-right: 1.64vw; }

  .contacts > div:first-child > a {
    line-height: 4.67vw; }

  .contacts > div:last-child {
    font-size: 1.64vw; }

  .menu {
    display: initial;
    cursor: pointer; }

  .menuIcon {
    height: 13px;
    width: 3.57vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

  .menuIcon > * {
    height: 3px;
    width: 100%;
    background-color: black; }

  .modalMenu > div,
  .stickyModalMenu > div {
    width: 59vw !important;
    margin: 0 !important;
    right: 4.12vw;
    top: 11.53vw;
    position: absolute; }

  .showModalMargin > div {
    top: 19.03vw; }

  .stickyModalMenu > div {
    top: 8vw; }

  .close {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: -6vw;
    width: 3.57vw;
    height: 3.57vw; }

  .close:hover {
    opacity: 1; }

  .close:before, .close:after {
    position: absolute;
    left: 1.37vw;
    content: ' ';
    height: 3.57vw;
    width: 0.4vw;
    background-color: white; }

  .close:before {
    transform: rotate(45deg); }

  .close:after {
    transform: rotate(-45deg); }

  .menuList > li {
    padding: 0 4.94vw;
    height: 6.86vw;
    font-size: 2.47vw;
    line-height: 6.86vw;
    border-bottom: 0.4vw solid #f1f1f1; }

  .menuList > li:last-child {
    border-bottom: 0; }

  .headerButton {
    display: none; }

  .stickyHeader {
    height: 7.55vw; }

  .stickyHeader > .contacts > div:last-child {
    display: none; } }

@media (max-width: $mobile-width) {
  .Header {
    height: 12.17vw; }

  .showMargin {
    top: 13.04vw; }

  .Header > .logo {
    width: 33.91vw; }

  .contacts > div:first-child {
    min-width: 34.78vw;
    font-size: 3.91vw; }

  .contacts > div:first-child > img {
    width: 2.39vw;
    height: 3.04vw;
    margin-right: 2.17vw; }

  .contacts > div:first-child > a {
    line-height: 4.67vw; }

  .contacts > div:last-child {
    display: none; }

  .close {
    top: -9vw;
    width: 4.78vw;
    height: 4.78vw; }

  .close:hover {
    opacity: 1; }

  .close:before, .close:after {
    left: 2.17vw;
    content: ' ';
    height: 5.65vw;
    width: 0.6vw; }

  .menuIcon {
    width: 5.65vw; }

  .modalMenu > div,
  .stickyModalMenu > div {
    width: 93.47vw !important;
    top: 12.17vw;
    position: absolute; }

  .showModalMargin > div {
    top: 25.21vw; }


  .menuList > li {
    padding: 0 4.94vw;
    height: 10.86vw;
    line-height: 10.86vw;
    font-size: 3.91vw;
    border-bottom: 0.6vw solid #f1f1f1; }

  .menuList > li:last-child {
    border-bottom: 0; } }

