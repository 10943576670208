@import "../../config.sass";

.notificator {
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $blue-color;
  min-height: 3.15vw;
  margin: 0 !important;
  padding: 0 9.48vw;
  transition: 0.2s; }

.notificator > div,
.notificator > div > * > a {
  color: white;
  font-size: 0.84vw;
  font-weight: bold; }

.notificator > div > * > a {
  border-bottom: 0.1vw solid var(--color2); }

.notificator > div > * > a:hover {
  color: white; }

.notificator > img {
  cursor: pointer;
  width: 1.47vw;
  height: 1.47vw;
  transition: 0.1s; }

.notificator > img:hover {
  opacity: 0.9; }

.notificator {
  min-height: 4.34vw; }


.notificator > div,
.notificator > div > * > a {
  font-size: 1.15vw; }

.notificator > div > * > a {
  border-bottom: 0.15vw solid var(--color2); }


.notificator > img {
  width: 2.02vw;
  height: 2.02vw; }



@media (max-width: $tablet-width) {
  .notificator {
    min-height: 7.5vw;
    padding: 0 4.12vw; }


  .notificator > div,
  .notificator > div > * > a {
    font-size: 2vw; }

  .notificator > div > * > a {
    border-bottom: 1px solid var(--color2); }


  .notificator > img {
    width: 3.5vw;
    height: 3.5vw; } }

@media (max-width: $mobile-width) {
  .notificator {
    min-height: 13.04vw; }

  .notificator > div {
    width: 100%; }

  .notificator > div,
  .notificator > div > * > a {
    font-size: 3.47vw; }

  .notificator > div > * > a {
    border-bottom: 0.2vw solid var(--color2); }


  .notificator > img {
    width: 6.08vw;
    height: 6.08vw; } }


