@import "../../config.sass";

.button {
    text-align: center;
    line-height: 3.94vw;
    font-size: 1.31vw;
    font-weight: bold;
    width: 100%;
    border-radius: 0.72vw;
    height: 3.94vw;
    border-width: 0;
    color: white;
    transition: 0.2s; }

.button:not(.noblick_btn) {
  position: relative;
  overflow-x: hidden; }

.button:not(.noblick_btn):after {
  content: '';
  position: absolute;
  top: 0;
  left: -3em;
  width: 20px;
  height: 100%;
  background-color: #fff;
  opacity: 0.4;
  transform: skewX(-45deg);
  animation-name: moveBlick;
  animation-duration: 5s;
  animation-delay: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite; }

@keyframes moveBlick {
  0% {
    transform: translateX(-3em) skewX(-45deg); }
  80% {
    transform: translateX(-3em) skewX(-45deg); }
  100% {
    transform: translateX(20em) skewX(-45deg); } }

@media (max-width: $mobile-width) {
    @keyframes moveBlick {
      0% {
        transform: translateX(0) skewX(-45deg); }
      80% {
        transform: translateX(0) skewX(-45deg); }
      100% {
        transform: translateX(100vw) skewX(-45deg); } } }


.button:hover, .default:active, .default:focus {
    color: white; }


.default, .default:visited {
    background-color: #06ae35; }


.default:hover, .default:active, .default:focus {
    background-color: #00c837; }

.orange, .orange:visited {
    background-color: #f14f00; }


.orange:hover, .orange:active, .orange:focus {
    background-color: #ff8023; }


.yellow, .yellow:visited {
    background-color: #e9f40c;
    color: black; }


.yellow:hover, .yellow:active, .yellow:focus {
    background-color: #f4ff16;
    color: black; }


@media (max-width: $tablet-width) {
    .button {
        border-radius: 1.37vw;
        height: 7.41vw;
        line-height: 7.41vw;
        font-size: 2.47vw; } }


@media (max-width: $mobile-width) {
    .button {
        border-radius: 1.37vw;
        height: 7.41vw;
        line-height: 7.41vw;
        font-size: 2.47vw; } }

@media (max-width: $mobile-width) {
    .button {
        border-radius: 2.17vw;
        height: 11.73vw;
        line-height: 11.73vw;
        font-size: 3.91vw; } }

