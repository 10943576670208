/*

Здесь задаются глобальные стили для всего проекта,
а также переопределяется поведение стилей библиотеки Ant Design

*/

@import './slick.css';

@font-face {
  font-family: OpenSans;
  src: url("./assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

/*@font-face {
  font-family: OpenSansBold;
  src: url("./assets/fonts/Open_Sans/OpenSans-Bold.ttf");
}
@font-face {
  font-family: OpenSansSemiBold;
  src: url("./assets/fonts/Open_Sans/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: PTSans;
  src: url("./assets/fonts/PT_Sans/PT_Sans-Web-Regular.ttf");
}

@font-face {
  font-family: PTSansBold;
  src: url("./assets/fonts/PT_Sans/PT_Sans-Web-Bold.ttf");
}*/

body {
  margin: 0;
  padding: 0;
  font-family: OpenSans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: OpenSans, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a, a:hover, a:focus, a:visited {
  text-decoration: none;
}

/*div > ymaps > ymaps > ymaps > ymaps:nth-child(2) {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.1 0.5 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); !* Firefox 3.5+ *!
  -webkit-filter: grayscale(100%); !* Chrome 19+ & Safari 6+ *!
}*/

.whiteInput {
  border-radius: 0.72vw !important;
  height: 3.94vw !important;
  border: 0 solid white !important;
  transition: 0.2s;
}

.whiteInput > div > input {
  height: 3.94vw !important;
  padding: 0.72vw 2.91vw !important;
  font-size: 1.31vw;
}

.whiteInput:hover,
.whiteInput:focus,
.whiteInput:active,
.ant-input-number-focused:hover,
.ant-input-number-focused:focus,
.ant-input-number-focused:active,
.ant-input-number-input:hover,
.ant-input-number-input:focus,
.ant-input-number-input:active {
  outline: 0 !important;
  box-shadow: initial !important;
  border: 0 !important;
}

.ant-slider-handle:focus {
  border-color: #06ae35 !important;
  box-shadow: 0 0 0 5px #06ae35 !important;
}

.ant-slider-handle {
  border: solid 2px #06ae35 !important;
  background-color: #06ae35 !important;
  background-repeat: no-repeat;
  background-position: -3px -3px;
  margin-left: -12px;
  margin-top: -10px;
  width: 25px;
  height: 25px;
}

.ant-slider-rail, .ant-slider-dot {
  background-color: #e6f7f6;
}

.ant-slider-handle:focus {
  box-shadow: initial !important;
}

.ant-slider-handle:focus {
  box-shadow: initial !important;
  outline: initial !important;
}

.ant-slider:hover .ant-slider-track {
  background-color: #06ae35;
}

.ant-slider-track, .ant-slider-track:hover, .ant-slider-track:focus, .ant-slider-track:active {
  background-color: #06ae35;
}

.ant-slider-dot-active {
  background-color: #06ae35 !important;
  border-color: #06ae35 !important;
}

.ant-slider-dot {
  border: inherit;
  border-radius: inherit;
  height: 10px;
  width: 2px;
}

.ant-slider-dot {
  height: 0;
}

.ant-slider-dot:nth-child(2n) {
  top: -3px;
}

.ant-slider-dot:nth-child(2n+1) {
  top: -2px;
}

.ant-slider-dot:first-child, .ant-slider-dot:last-child {
  margin-left: -2px;
  height: 10px;
  top: -3px;
}

.ant-slider-dot:nth-child(5) {
  margin-left: -2px;
  top: -3px;
}

.ant-modal {
  margin: 0 auto !important;
  width: 67.44vw !important;
}

.ant-modal-footer, .ant-modal-header {
  display: none;
}

.ant-modal-close {
  display: none;
}

.ant-modal-close > span {
  width: inherit;
  height: inherit;
  line-height: 30px;
  color: white;
}

.ant-modal > div {
  border-radius: 10px !important;
  background-color: #f1f1f1;
}

.ant-modal-body {
  padding: 0 !important;
}

/*.ant-modal-body > * {
  background-color: #f1f1f1;
}*/

.disabledLink {
  opacity: 0.5;
  cursor: initial;
  pointer-events: none;
}

.ant-input-number {
  width: 100%;
}

.ant-input-number-focused {
  box-shadow: initial;
}

.ant-input-number-handler-wrap {
  display: none;
}

@media (max-width: 900px) {
  .whiteInput {
    border-radius: 1.37vw !important;
    height: 7.41vw !important;
  }

  .whiteInput > div > input {
    text-align: center;
    height: 7.41vw !important;
    font-size: 2.47vw;
  }

  .ant-modal {
    width: 92vw !important;
  }
}

@media (max-width: 460px) {
  .whiteInput {
    border-radius: 2.17vw !important;
    height: 11.73vw !important;
  }

  .whiteInput > div > input {
    height: 11.73vw !important;
    font-size: 3.91vw;
  }

}




