@import "../../config.sass";

.Tabber > header {
    display: flex;
    cursor: pointer; }


.Tabber > div {
    transition: 0.2s;
    margin-top: 3.64vw; }


.Tabber > header > div {
    width: 50%;
    height: 6.56vw;
    line-height: 6.56vw;
    text-align: center;
    font-size: 2.04vw;
    font-weight: bold;
    border-bottom: 0.24vw solid #dcdcdc; }


.activeLeftHeader > div:first-child {
    height: 7vw;
    line-height: 6.56vw;
    border-bottom: 0 !important;
    border-right: 0.24vw solid #dcdcdc;
    border-top: 0.24vw solid #dcdcdc;
    border-left: 0.24vw solid #dcdcdc;
    border-top-right-radius: 0.72vw;
    border-top-left-radius: 0.72vw; }


.activeLeftHeader > div:last-child {
    margin-top: 1vw;
    line-height: 5vw;
    margin-left: -0.24vw;
    border-left: 0.24vw solid #dcdcdc;
    border-bottom-left-radius: 0.72vw; }


.activeRightHeader > div:first-child {
    margin-top: 1vw;
    line-height: 5vw;
    margin-right: -0.24vw;
    border-right: 0.24vw solid #dcdcdc;
    border-bottom-right-radius: 0.72vw; }


.activeRightHeader > div:last-child {
    height: 7vw;
    line-height: 6.56vw;
    border-bottom: 0 !important;
    border-left: 0.24vw solid #dcdcdc;
    border-top: 0.24vw solid #dcdcdc;
    border-right: 0.24vw solid #dcdcdc;
    border-top-left-radius: 0.72vw;
    border-top-right-radius: 0.72vw; }


@media (max-width: $tablet-width) {
    .Tabber > header {
        flex-direction: column; }

    .Tabber > div {
        margin-top: 6.04vw; }

    .Tabber > header > div {
        width: 100%;
        height: 10.16vw;
        line-height: 9.16vw !important;
        text-align: left;
        font-size: 3.84vw;
        border-radius: 1.37vw !important;
        font-weight: bold;
        color: #dcdcdc;
        border: 0.54vw solid #dcdcdc !important;
        margin: 0 !important;
        padding: 0 4.94vw; }

    .activeLeftHeader > div:first-child {
        height: 10.16vw;
        line-height: 9.16vw;
        color: black;
        border: 0.54vw solid black !important; }


    .activeLeftHeader > div:last-child {
        margin-top: initial;
        line-height: 9.16vw; }

    .activeRightHeader > div:first-child {
        margin-top: 0;
        line-height: 9.16vw; }

    .activeRightHeader > div:last-child {
        height: 10.16vw;
        line-height: 9.16vw;
        color: black;
        border: 0.54vw solid black !important; }

    .Tabber > header > div:first-child {
        margin-bottom: 2.2vw !important; } }


@media (max-width: $mobile-width) {
    .Tabber > div {
        margin-top: 9.56vw; }

    .Tabber > header > div {
        height: 16.08vw;
        line-height: 14vw !important;
        text-align: left;
        font-size: 3.91vw;
        border-radius: 2.17vw !important;
        border: 0.86vw solid #dcdcdc !important;
        margin: 0 !important;
        padding: 0 7.82vw; }

    .activeLeftHeader > div:first-child {
        height: 16.08vw;
        line-height: 14vw;
        border: 0.84vw solid black !important; }

    .activeLeftHeader > div:last-child {
        line-height: 16.08vw; }

    .activeRightHeader > div:first-child {
        margin-top: 0;
        line-height: 14vw; }

    .activeRightHeader > div:last-child {
        height: 16.08vw;
        line-height: 14vw;
        border: 0.84vw solid black !important; }

    .Tabber > header > div:first-child {
        margin-bottom: 3.47vw !important; } }



