@import "../../config.sass";

.App {
    display: flex;
    flex-direction: column; }

.App > * {
  margin: 0 9.48vw; }

.Loans {
    margin: 0 !important; }

.anchor {
    position: relative;
    top: -6vw; }

.Calc section section {
    margin: 0 !important; }

.Calc h2 {
    margin-bottom: 0px !important; }

.App :global(.ant-back-top) {
    z-index: 100;
    margin: 0 !important;
    right: 13vw;
    bottom: 5vw; }

.mover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.05vw;
    height: 4.05vw;
    border-radius: 0.72vw;
    background-color: $blue-color;
    color: white; }

.mover > i {
    font-size: 2.6vw;
    line-height: 2.6vw; }


@media (max-width: $tablet-width) {
    .App > * {
        margin: 0 4.12vw; }

    .App :global(.ant-back-top) {
        margin: 0 !important;
        right: 10.18vw;
        bottom: 8vw; }

    .mover {
        width: 7vw;
        height: 7vw;
        border-radius: 1.25vw; }


    .mover > i {
        color: white;
        font-size: 4vw;
        line-height: 4vw; } }


@media (max-width: $mobile-width) {
    .App > * {
        margin: 0 4.12vw; }

    .App :global(.ant-back-top) {
        margin: 0 !important;
        right: 12.18vw;
        bottom: 10vw; }

    .mover {
        width: 11vw;
        height: 11vw;
        border-radius: 2.17vw; }


    .mover > i {
        font-size: 6.95vw;
        line-height: 6.95vw; } }



.messengers {
  position: fixed;
  bottom: 5vw!important;
  right: 2vw!important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 1.5vw;
  z-index: 100;
  margin: 0!important; }

.messengers > a {
  width: 4.05vw;
  height: 4.05vw;
  border-radius: 0.72vw;
  cursor: pointer;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; }

.messengers > a img {
  width: 100%; }

@media (max-width: $tablet-width) {
  .messengers {
    bottom: 5vw!important; }

  .messengers > a {
    width: 7vw;
    height: 7vw;
    border-radius: 1.25vw; } }


@media (max-width: $mobile-width) {
  .messengers {
    // right: 13.5vw !important
    bottom: 5vw!important; }

  .messengers > a {
    width: 11vw;
    height: 11vw;
    border-radius: 2.17vw; } }


