@import "../../config.sass";

.contactsInfo {
    display: flex;
    justify-content: space-between;
    align-items: center; }


.contactsInfo > article:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 4.34vw; }


.contacts {
    display: flex;
    align-items: center; }


.contacts > div:first-child {
    display: flex;
    align-items: center;
    min-width: 16.93vw;
    font-size: 1.89vw;
    margin-right: 1.45vw; }


.contacts > div:first-child > img {
    width: 1.09vw;
    height: 1.45vw;
    margin-right: 0.87vw; }


.contacts > div:first-child > a {
    cursor: pointer;
    color: #000;
    text-decoration: none;
    line-height: 2.48vw; }


.contacts > div:last-child {
    font-size: 1.31vw;
    color: #767676; }


.address {
    display: flex;
    font-size: 1.31vw;
    color: black; }


.address > div > img {
    width: 1.6vw;
    height: 1.31vw;
    margin-right: 0.43vw;
    vertical-align: baseline; }


.address > span {
    font-weight: bold;
    margin-right: 1.45vw; }


.contactsInfo > article:last-child {
    display: flex;
    align-items: center;
    width: 27.15vw;
    border: 0.29vw solid $blue-color;
    border-radius: 0.72vw;
    padding: 0.72vw 1.6vw;
    max-height: 5.54vw; }


.contactsInfo > article:last-child > img {
    width: 2.62vw;
    margin-right: 1.89vw; }


.contactsInfo > article:last-child > p {
    margin: 0;
    font-size: 1.31vw; }


.map {
    width: 100%;
    height: 33.86vw;
    margin-top: 4.08vw;
    margin-bottom: 5.62vw; }

.nowrap {
    white-space: nowrap; }

.disableMoscow > header > div:last-child {
    color: lightgray;
    cursor: initial;
    pointer-events: none; }

@media (max-width: $tablet-width) {

    .contactsInfo > article:first-child {
        min-height: 17.3vw; }


    .contacts {
        display: flex;
        align-items: flex-start;
        flex-direction: column; }

    .contacts > div:first-child {
        min-width: 16.93vw;
        font-size: 3.57vw;
        line-height: 4.67vw;
        margin-right: 2.74vw; }

    .contacts > div:first-child > img {
        width: 2.47vw;
        height: 3.84vw;
        margin-right: 1.64vw; }


    .contacts > div:first-child > a {
        line-height: 4.67vw; }

    .contacts > div:last-child {
        font-size: 2.47vw; }

    .address {
        flex-direction: column;
        font-size: 2.47vw; }

    .address > div > img {
        width: 3.02vw;
        height: 2.47vw;
        margin-right: 0.82vw; }

    .address > span {
        margin-right: 2.74vw; }

    .contactsInfo {
        align-items: flex-start; }

    .contactsInfo > article:last-child {
        width: 46.7vw;
        border: 0.43vw solid $blue-color;
        border-radius: 1.37vw;
        padding: 1.37vw 3.02vw;
        min-height: 10.16vw; }


    .contactsInfo > article:last-child > img {
        width: 4.94vw;
        margin-right: 3.57vw; }


    .contactsInfo > article:last-child > p {
        font-size: 2.47vw; }

    .map {
        width: 100%;
        height: 63.73vw;
        margin-top: 4.08vw;
        margin-bottom: 5.62vw; } }


@media (max-width: $mobile-width) {
    .contactsInfo > article:first-child {
        min-height: 17.3vw; }

    .contacts {
        margin-bottom: 5.65vw; }

    .contacts > div:first-child {
        min-width: 16.93vw;
        font-size: 5.65vw;
        line-height: 7.39vw;
        margin-right: 0; }

    .contacts > div:first-child > img {
        width: 3.91vw;
        height: 6.08vw;
        margin-right: 2.6vw; }


    .contacts > div:first-child > a {
        line-height: 7.39vw; }

    .contacts > div:last-child {
        font-size: 3.91vw; }

    .address {
        font-size: 3.91vw;
        margin-bottom: 5.65vw; }

    .address > div > img {
        width: 4.78vw;
        height: 3.91vw;
        margin-right: 1.3vw; }

    .address > span {
        margin-right: 4.34vw; }

    .contactsInfo {
        flex-direction: column;
        align-items: flex-start; }

    .contactsInfo > article:last-child {
        width: 100%;
        border: 0.6vw solid $blue-color;
        border-radius: 2.17vw;
        padding: 1.37vw 5.21vw;
        min-height: 16.08vw; }


    .contactsInfo > article:last-child > img {
        width: 7.82vw;
        margin-right: 5.65vw; }


    .contactsInfo > article:last-child > p {
        font-size: 3.91vw; }

    .map {
        width: 100%;
        height: 100vw;
        margin-top: 9.56vw;
        margin-bottom: 8.69vw; } }


