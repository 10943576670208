@import "../../config.sass";

.anchor {
  position: relative;
  top: -20vw; }

.Testimonials {
  background-color: #f1f1f1;
  border-radius: 0.72vw; }

.video {
  padding: 10px;
  text-align: center;
  height: 15vw; }

.video > div {
  width: 100% !important;
  height: 100% !important; }

.video iframe {
  border-radius: 0.72vw; }

.video video {
  border-radius: 0.72vw;
  width: 100% !important;
  height: 100% !important; }

@media (max-width: $tablet-width) {
  .video {
    height: 50vw !important; } }

@media (max-width: $mobile-width) {
  .video {
    height: 50vw !important; } }
