@import "../../config.sass";

.anchor {
    position: relative;
    top: -14vw; }

.SupportBlock {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #f1f1f1;
    border-radius: 0.72vw;
    width: 100%;
    min-height: 22.62vw;
    padding: 5.1vw 5.83vw; }


.SupportBlock > article {
    width: 38.5vw; }


.SupportBlock > article > h2 {
    font-size: 3.35vw;
    line-height: 3.64vw;
    color: black;
    font-weight: bold;
    margin-bottom: 2.33vw; }


.SupportBlock > article > p {
    font-size: 2.04vw;
    line-height: 2.62vw;
    margin: 0; }

.customForm {
    flex-direction: column; }

.customForm :global(input) {
    text-align: center; }

.customForm > * {
    width: 100% !important;
    margin-right: 0 !important; }

.customForm > div:first-child {
    margin-bottom: 1.16vw; }

.customForm > div:last-child > p {
    text-align: center;
    color: #010101;
    opacity: 0.4; }

.customForm > div:last-child > p > span {
    text-decoration: none;
    border-bottom: 1px dashed #010101;
    cursor: pointer;
    transition: 0.1s; }

@media (max-width: $tablet-width) {
    .SupportBlock {
        flex-direction: column;
        border-radius: 1.37vw;
        width: 100%;
        min-height: 64.28vw;
        padding: 7.41vw 7.41vw; }

    .SupportBlock > article {
        width: 100%; }

    .SupportBlock > article > h2 {
        font-size: 6.18vw;
        line-height: 7vw;
        margin-bottom: 4.34vw; }


    .SupportBlock > article > p {
        font-size: 3.84vw;
        line-height: 4.94vw;
        margin: 0; }

    .customForm {
        width: 100%; }

    .customForm > div:first-child {
        margin-bottom: 2.2vw; }

    .customForm > div:last-child > p {
        text-align: left;
        margin-top: 1.64vw;
        font-size: 1.64vw; } }

@media (max-width: $mobile-width) {
    .SupportBlock {
        border-radius: 2.17vw;
        min-height: 102vw;
        padding: 9.56vw 7.39vw; }

    .SupportBlock > article > h2 {
        font-size: 7.82vw;
        line-height: 10.69vw;
        width: 52vw;
        margin-bottom: 6.95vw; }


    .SupportBlock > article > p {
        font-size: 3.91vw;
        line-height: 4.78vw; }

    .customForm > div:first-child {
        margin-bottom: 3.47vw; }


    .customForm > div:last-child > p {
        margin-top: 2.6vw;
        font-size: 2.6vw; }

    .customForm > div:last-child > p > span {
        border-bottom: 1px dashed #010101; }


    .customForm > div:last-child > p > span:hover {
        border-bottom: 1px dashed $blue-color; } }

