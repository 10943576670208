@import "../../config.sass";

.clear {
    clear: both; }

.countdown * {
    margin-top: 0px !important; }

.countdown {
    margin-top: 1vw;
    margin-bottom: 1vw; }

.countdownTitle {
    font-size: 1.9vw;
    margin: 0; }

.digits {
    text-align: center;
    width: 7.1vw !important;
    float: left; }

.digits::before {
    content: ":";
    font-size: 2.4vw;
    line-height: 4vw; }

.digits:last-of-type::before {
    content: ""; }

.digits .digit {
    float: left;
    background-color: #ff6f00;
    color: white;
    font-weight: bold;
    border-radius: 0.6vw;
    padding: 0.4vw 0.8vw;
    display: inline-block;
    margin-left: 0.2vw;
    margin-top: 0px !important;
    font-size: 2.4vw; }

.advertBlock {
    margin-top: 50px;
    border: solid 1px #fff;
    background: linear-gradient(262.75deg,#a0cb83 5.87%,#06ae35 96.56%);
    box-shadow: 0 4px 4px rgba(164,48,255,.25);
    color: #fff;
    cursor: pointer; }

.advertBlock h2 {
    color: #fff !important; }

.advertBlock a, .advertBlock a:visited, .advertBlock a:focus, .advertBlock a:hover {
    box-shadow: 0 10px 28px 0 rgba(255,168,0,.6);
    background-color: #ff6f00;
    border: 1px solid #fff;
    height: 5vw;
    font-size: 1.4vw;
    line-height: 5vw; }

.advertBlock div {
    margin-top: 35px; }

.advertBlock div p {
    margin-top: 20px;
    line-height: 1.5vw; }

.Stats {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4.96vw;
    margin-bottom: 0; }

.Stats > article:first-child {
    width: 69%;
    padding-right: 6.56vw;
    margin-right: 0px; }

.Stats > article:nth-child(2) {
    width: 31%;
    margin: 0px !important; }

.Stats > article:nth-child(3) {
    margin-right: 0; }

.links {
    display: none;
    width: 100%; }

.links > div {
    cursor: pointer;
    border-bottom: 0.1vw solid #767676; }

.periodLink {
    transition: 0.1s;
    cursor: pointer; }

.periodLink:hover,
.periodLink:hover > span {
    color: $blue-color; }

.periodLink > span {
    color: red; }

.link {
    color: black;
    border-bottom: 0 !important; }

.info {
    padding: 4.96vw 10.21vw 2.91vw; }


.info {
    text-align: center; }


.info > p {
    margin: 0;
    font-size: 1.31vw;
    line-height: 2.04vw; }


.info > div {
    width: 13.43vw;
    margin: 2.62vw auto 0; }

.noblick_btn {
    overflow-x: visible !important; }

.noblick_btn::after {
    display: none; }


@media (max-width: $tablet-width) {
    .Stats > article {
        width: 100% !important;
        padding-right: 0px !important; }

    .digits {
        width: 13.1vw !important; }

    .digits::before {
        font-size: 5.4vw;
        line-height: 7vw; }

    .digits .digit {
        border-radius: 0.8vw;
        padding: 0.9vw 1.8vw;
        font-size: 3.4vw; }

    .countdownTitle {
        font-size: 3.9vw; }

    .calcButton {
        font-size: 2vw; }

    .advertBlock {
        min-height: auto !important; }

    .advertBlock h2 {
        color: #fff !important; }

    .advertBlock a, .advertBlock a:visited, .advertBlock a:focus, .advertBlock a:hover {
        height: 12vw;
        font-size: 4vw;
        line-height: 12vw; }

    .advertBlock .advertBlockButton {
        width: 100%; }

    .advertBlock div p {
        line-height: 2.5vw; }

    .Stats {
        margin-top: 9.89vw;
        margin-bottom: 4.4vw; }

    .links {
        display: flex;
        color: #767676;
        margin-top: 5.49vw;
        font-size: 2.47vw; }

    .links > div {
         line-height: 3.84vw; }

    .links > div:first-child {
        margin-right: 9.61vw; }

    .info {
        padding: 9.96vw 10.21vw 9.96vw; }


    .info > p {
        font-size: 2.47vw;
        line-height: 4.39vw; }


    .info > div {
        width: 40vw;
        margin: 7.62vw auto 0; } }


@media (max-width: $mobile-width) {
    .Stats > article {
        width: 100% !important;
        padding-right: 0px !important; }

    .calcButton {
        font-size: 3.47vw; }

    .advertBlock {
        min-height: auto !important; }

    .advertBlock h2 {
        font-size: 6.8vw !important;
        width: 100% !important; }

    .advertBlock a, .advertBlock a:visited, .advertBlock a:focus, .advertBlock a:hover {
        height: 20vw;
        font-size: 5vw;
        line-height: 20vw; }

    .advertBlock .advertBlockButton {
        width: 100%; }

    .advertBlock div p {
        font-size: 3.3vw;
        line-height: 4vw; }


    .Stats {
        margin-top: 10.86vw;
        margin-bottom: 6.52vw; }

    .Stats :global(.ant-slider) {
        margin: 6.52vw 0 9.56vw; }

    .links {
        display: flex;
        color: #767676;
        margin-top: 8.69vw;
        font-size: 3.91vw;
        line-height: 3.02vw; }

    .links > div {
        line-height: 5.65vw; }

    .links > div:first-child {
        margin-right: 6.52vw; }

    .info {
        padding: 9.96vw 10.21vw 9.96vw; }


    .info > p {
        font-size: 3.91vw;
        line-height: 5.04vw; }


    .info > div {
        width: 40vw;
        margin: 7.62vw auto 0; }

    .countdown .countdownTitle {
        font-size: 4vw;
        margin-bottom: 0.1vw; }
    .digits {
        width: 14vw !important; }
    .digit {
        padding: 1vw 1.6vw;
        margin-left: 0.4vw; }

    .digitCaption {
        font-size: 3vw; } }




